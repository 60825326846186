import React, { useState, useEffect } from 'react';
import Typography from '../../../../components/atoms/Typography/Typography';
import Flex from '../../../../components/atoms/Flex/Flex';

const ResendTokenLink = ({
  onClick,
}) => {
  const [countdown, setCountdown] = useState(30);

  useEffect(() => {
    let intervalId;

    if (countdown > 0) {
      intervalId = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [countdown]);

  const handleResendToken = () => {
    onClick();
    setCountdown(30);
  };

  return (
    <Flex flexDirection={"column"} gapSize>
      <Typography
        variant="subheading2"
        fontWeight={600}
        color={"terceary"}
        style={{
          alignSelf: "center",
        }}
        onClick={countdown === 0 ? handleResendToken : undefined}
      >
        Ainda não recebeu seu código?
      </Typography>
      <Typography
        variant="subheading2"
        fontWeight={500}
        color={"primary"}
        style={{
          textDecoration: "underline",
          cursor: countdown > 0 ? "not-allowed" : "pointer",
          alignSelf: "center",
        }}
        onClick={countdown === 0 ? handleResendToken : undefined}
      >
        {countdown > 0 ? `Receber novo código em ${countdown} segundos` : "Reenviar código"}
      </Typography>
    </Flex>
  );
};

export default ResendTokenLink;
