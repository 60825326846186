import styled from "styled-components";
import PropTypes from 'prop-types'

const SPACING = {
  sm: '10px',
  md: '20px',
  lg: '30px',
}

const Flex = styled.div`
  display: flex;
  ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent};`}
  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
  ${({ flexDirection }) => flexDirection && `flex-direction: ${flexDirection};`}
  ${({ gapSize }) => gapSize && `gap: ${SPACING[gapSize || 'sm'] ?? SPACING.sm};`}
`

Flex.propTypes = {
  justifyContent: PropTypes.string,
  alignItems: PropTypes.string,
  flexDirection: PropTypes.string,
}

export default Flex