export const getProviderName = (name) => {
  switch (name) {
    case 'intermedica':
      return "Intermédica";
    case 'levesaude':
      return "Leve Saúde";
    case 'assim':
      return "Assim Saúde";
    case 'goldencross':
      return "Golden Cross";
    case 'amil':
      return "Amil";
    case 'sulamerica':
      return "SulAmérica";
    case 'portoseguro':
      return "Porto Seguro";
    case 'bradesco':
      return "Bradesco";
    case 'omint':
      return "Omint";
  }
}
