import { useCallback } from "react";
import { FaCloudUploadAlt, FaFileImage, FaRegTimesCircle, FaCheckCircle } from "react-icons/fa";
import Flex from "../atoms/Flex/Flex";
import Typography from "../atoms/Typography/Typography";
import { useDropzone } from 'react-dropzone';

const UploadFile = ({
  archiveName,
  title,
  subtitle,
  disclaimer,
  onUploadFile,
  presignedUrl,
  isUploaded = false,
}) => {
  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    onUploadFile(archiveName, file);
  }, [presignedUrl, archiveName, onUploadFile]);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ onDrop });

  const truncateString = (str) => {
    if (str.length <= 20) {
      return str;
    } else {
      return str.substring(0, 20) + "...";
    }
  }

  return (
    <Flex
      flexDirection={"column"}
      style={{
        width: "100%",
        cursor: "pointer",
      }}
    >
      <div
        {...getRootProps()}
        style={{
          border: "1px solid rgba(0, 0, 0, 0.4)",
          borderStyle: "dashed",
          borderRadius: "10px",
          position: "relative",
        }}
      >
        <Flex
          justifyContent={"space-evenly"}
          alignItems={"center"}
          style={{
            margin: 15,
            gap: 14,
          }}
        >
          <input {...getInputProps()} />
          {acceptedFiles.length ?
            <Flex justifyContent={"space-around"} style={{ width: "100%" }}>
              <FaFileImage color="#5900CC" />
              {acceptedFiles.map(file => (
                <Typography
                  variant="subheading2"
                  fontWeight={200}
                  key={file.path}
                >
                  {truncateString(file.path)} - {(file.size / 1024).toFixed(1)} kB
                </Typography>
              ))}
              <FaRegTimesCircle />
            </Flex>
            :
            <>
              <FaCloudUploadAlt color="#5900CC" />
              <Flex
                flexDirection={"column"}
                gapSize
              >
                <Typography
                  variant="subheading2"
                  fontWeight={200}
                  color={"primary"}
                >
                  {title}
                </Typography>
                <Typography
                  variant="subheading2"
                  fontWeight={200}
                  color={"terceary"}
                >
                  {subtitle}
                </Typography>
              </Flex>
            </>
          }
          {isUploaded && (
            <Flex
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FaCheckCircle style={{ color: 'green', fontSize: 30 }} />
            </Flex>
          )}
        </Flex>
      </div>
      <Typography
        variant="subheading2"
        fontWeight={200}
        color={"terceary"}
        style={{
          marginTop: 10,
        }}
      >
        {disclaimer}
      </Typography>
    </Flex>
  )
}

export default UploadFile;
