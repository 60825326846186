import Flex from "../../../../components/atoms/Flex/Flex";
import Typography from "../../../../components/atoms/Typography/Typography";
import Button from "../../../../components/molecules/Button";
import Input from "../../../../components/molecules/Input";
import { dependentHealthDeclarationPageModel } from "./viewModel/dependentHealthDeclarationPageModel";

const DependentHealthDeclarationPageRoute = () => {
  const {
    onClickConfirm,
  } = dependentHealthDeclarationPageModel();
  return (
    <Flex
      flexDirection={"column"}
      justifyContent={"space-between"}
      alignItems={"center"}
      style={{
        height: "100%",
        gap: "12px",
        margin: 20,
      }}
    >
      <Flex
        flexDirection={"column"}
        gapSize
        style={{
          marginTop: "120px",
        }}
      >
        <Typography
          variant="h4"
          fontWeight={200}
          color={"terceary"}
          style={{
            marginLeft: 50,
            marginRight: 50,
            marginBottom: 10,
          }}
        >
          Declaração de saúde
        </Typography>
        <Flex
          flexDirection={"column"}
          alignItems={"center"}
          style={{
            gap: 20,
            margin: 20,
          }}
        >
          <Input
            placeholder={"Altura"}
            type={"text"}
            defaultValue={""}
            onChange={() => { }}
          />
          <Input
            placeholder={"Peso"}
            type={"text"}
            defaultValue={""}
            onChange={() => { }}
          />
        </Flex>
        <Flex
          flexDirection={"column"}
          gapSize
          style={{
            margin: 20,
          }}
        >
          <Typography
            variant="subheading1"
            fontWeight={700}
            color={"terceary"}
          >
            Doença pré-existente?
          </Typography>
          <Flex
            onClick={() => {}}
            style={{ cursor: "pointer" }}
            gapSize
          >
            <input
              type="radio"
              checked={false}
            />
            <Typography
              variant={"h6"}
              fontWeight={400}
              color={"terceary"}
            >
              Sim
            </Typography>
          </Flex>
          <Flex
            onClick={() => {}}
            style={{ cursor: "pointer" }}
            gapSize
          >
            <input
              type="radio"
              checked={false}
            />
            <Typography
              variant={"h6"}
              fontWeight={400}
              color={"terceary"}
            >
              Não
            </Typography>
          </Flex>
          <Input
            placeholder={"Qual?"}
            type={"text"}
            defaultValue={""}
            onChange={() => { }}
          />
          <Input
            placeholder={"Quando descobriu?"}
            type={"text"}
            defaultValue={""}
            onChange={() => { }}
          />
        </Flex>
        <Flex
          flexDirection={"column"}
          gapSize
          style={{
            margin: 20,
          }}
        >
          <Typography
            variant="subheading1"
            fontWeight={700}
            color={"terceary"}
          >
            Já fez alguma cirurgia (relacionada à doença)?
          </Typography>
          <Flex
            onClick={() => {}}
            style={{ cursor: "pointer" }}
            gapSize
          >
            <input
              type="radio"
              checked={false}
            />
            <Typography
              variant={"h6"}
              fontWeight={400}
              color={"terceary"}
            >
              Sim
            </Typography>
          </Flex>
          <Flex
            onClick={() => {}}
            style={{ cursor: "pointer" }}
            gapSize
          >
            <input
              type="radio"
              checked={false}
            />
            <Typography
              variant={"h6"}
              fontWeight={400}
              color={"terceary"}
            >
              Não
            </Typography>
          </Flex>
          <Input
            placeholder={"Quando?"}
            type={"text"}
            defaultValue={""}
            onChange={() => { }}
          />
        </Flex>
      </Flex>
      <Button
        text={"Continuar"}
        onClick={onClickConfirm}
        width={"163px"}
        height={"30px"}
        color={"rgba(89, 0, 204, 1)"}
        backgroundColor={'white'}
        disable={false}
      />
    </Flex>
  )
}

export default DependentHealthDeclarationPageRoute;