import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getFromLS } from "../../../../../utils/localStorage";
import { useEffect } from "react";
import { updateDependentUseCase } from "../useCases/mutations/updateDependentMutation"
import { getContractByIdQuery } from "../../../useCases/queries/getContractByIdQuery";
import { validateCPF, validateEmail } from "../../../../../utils/parser";
import { trackPage } from "../../../../../utils/segment";
export const dependentPersonalInfoPageModel = () => {
  const navigate = useNavigate();
  const [dependentForm, updateDependentForm] = useState({});
  const contract = getFromLS("contract")
  const [errors, setErrors] = useState({
    name: true,
    cpf: true,
    email: true,
    cellphone: true,
    civilStatus: true,
    occupation: true,
    height: true,
    weight: true,
  });
  const { dependentId } = useParams();
  trackPage("plg-contract-dependent-form");

  const {
    dataContractQuery,
    isLoadingContractQuery,
    isSuccessContractQuery,
  } = getContractByIdQuery()

  const {
    isLoadingUpdateDependentCase,
    isSuccessUpdateDependentCase,
    mutateUpdateDependent,
  } = updateDependentUseCase();

  const onClickConfirm = () => {
    mutateUpdateDependent({
      signerType: "dependent",
      simulationDependentId: dependentId,
      name: dependentForm.name,
      cpf: dependentForm.cpf,
      email: dependentForm.email,
      cellphone: dependentForm.cellphone,
      civilStatus: dependentForm.civilStatus,
      occupation: dependentForm.occupation,
      height: dependentForm.height,
      weight: dependentForm.weight,
      contractId: contract.id,
    })
  }

  const onChangeInput = (inputName, value) => {
    validateInput(inputName, value)
    updateDependentForm({
      ...dependentForm,
      [inputName]: value,
    })
  }

  const validateInput = (inputName, value) => {
    if (!value.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [inputName]: `${inputName} é obrigatório.`,
      }));
    } else {
      if (inputName === "cpf") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [inputName]: validateCPF(value) ? null : `Digite um CPF válido`,
        }));
      } else if (inputName === "email"){
        setErrors((prevErrors) => ({
          ...prevErrors,
          [inputName]: validateEmail(value) ? null : `Digite um email válido`,
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [inputName]: null,
        }));
      }
    }
  };
  const hasErrors = Object.values(errors).some((error) => error);

  useEffect(() => {
    if(!isLoadingUpdateDependentCase && isSuccessUpdateDependentCase) {
      navigate(`/contract/dependents/${dependentId}/documents-upload`);
    }
  }, [isLoadingUpdateDependentCase, isSuccessUpdateDependentCase])

  useEffect(() => {
    if (!isLoadingContractQuery && isSuccessContractQuery) {
      const dependent = dataContractQuery.signers?.find(signer =>
        signer.simulationDependentId === dependentId,
      );
      if (dependent) {
        setErrors({
          name: !dependent.name,
          cpf: !dependent.cpf,
          email: !dependent.email,
          cellphone: !dependent.cellphone,
          civilStatus: !dependent.civilStatus,
          occupation: !dependent.occupation,
          height: !dependent.height,
          weight: !dependent.weight,
        })
        updateDependentForm({
          name: dependent.name,
          cpf: dependent.cpf,
          email: dependent.email,
          cellphone: dependent.cellphone,
          civilStatus: dependent.civilStatus,
          occupation: dependent.occupation,
          height: dependent.height,
          weight: dependent.weight,
        })
      }
    }
  }, [isLoadingContractQuery, isSuccessContractQuery])

  return {
    onClickConfirm,
    dependentForm,
    onChangeInput,
    isLoading: isLoadingContractQuery,
    hasErrors,
    errors,
  }
}
