import { isMobile } from "react-device-detect";
import Flex from "../../../../components/atoms/Flex/Flex";
import Typography from "../../../../components/atoms/Typography/Typography";
import Button from "../../../../components/molecules/Button";
import Stepper from "../../../../components/molecules/Stepper";
import Loading from "../../../../components/molecules/Loading/Loading";
import UploadFile from "../../../../components/molecules/UploadFile";
import { documentsUploadPageModel } from "./viewModel/documentsUploadPageModel";

const DocumentsUploadPageRoute = () => {
  const {
    onClickHasInsuranceRadioButton,
    onClickHasntInsuranceRadioButton,
    onClickConfirm,
    handleUploadDocument,
    hasInsurance,
    presignedUrl,
    isLoading,
    isUploaded,
    documentsForm,
    hasErrors,
  } = documentsUploadPageModel();

  return (
    <Flex flexDirection={"column"} style={{ marginTop: 90 }}>
      <Stepper
        size={4}
        activeStep={4}
      />
      <Flex
        flexDirection={"column"}
        justifyContent={"space-between"}
        alignItems={"center"}
        style={{
          height: "100%",
          gap: "12px",
          margin: 20,
        }}
      >
        {isLoading && (<Loading/>)}
        <Flex
          flexDirection={"column"}
          gapSize
          style={{
            marginTop: "20px",
            ...(!isMobile && {
              width: "50%",
            }),
          }}
        >
          <Flex
            flexDirection={"column"}
            style={{
              marginLeft: 20,
              marginRight: 20,
              marginBottom: 10,
            }}
          >
            <Typography
              variant="h4"
              fontWeight={200}
              color={"terceary"}
            >
              Upload de documentos do titular
            </Typography>
            <Typography
              variant={"subheading1"}
              fontWeight={400}
              color={"terceary"}
              style={{
                marginTop: 12,
                alignText: "justify",
              }}
            >
              Os documentos serão compartilhados com a seguradora escolhida, 
              que vai analisar se está em conformidade com as regras de contratação
            </Typography>
          </Flex>
          <Flex
            flexDirection={"column"}
            gapSize
            style={{
              margin: 20,
            }}
          >
            <Typography
              variant="subheading1"
              fontWeight={700}
              color={"terceary"}
            >
              RG ou CNH
            </Typography>
            <UploadFile
              archiveName={"document"}
              title={"Selecione o arquivo ou arraste e solte aqui"}
              subtitle={"JPG, PNG ou PDF, arquivos de até 5MB."}
              disclaimer={"*O documento precisa conter o número do CPF"}
              presignedUrl={presignedUrl}
              onUploadFile={handleUploadDocument}
              isUploaded={isUploaded(documentsForm, "document")}
            />
          </Flex>
          <Flex
            flexDirection={"column"}
            gapSize
            style={{
              margin: 20,
            }}
          >
            <Typography
              variant="subheading1"
              fontWeight={700}
              color={"terceary"}
            >
              Comprovante de residência recente
            </Typography>
            <UploadFile
              archiveName={"residence"}
              title={"Selecione o arquivo ou arraste e solte aqui"}
              subtitle={"JPG, PNG ou PDF, arquivos de até 5MB."}
              presignedUrl={presignedUrl}
              onUploadFile={handleUploadDocument}
              isUploaded={isUploaded(documentsForm, "residence")}
            />
          </Flex>
          <Flex
            flexDirection={"column"}
            gapSize
            style={{
              margin: 20,
            }}
          >
            <Typography
              variant="subheading1"
              fontWeight={700}
              color={"terceary"}
            >
              Tem plano de saúde atual?
            </Typography>
            <Flex
              onClick={onClickHasInsuranceRadioButton}
              style={{ cursor: "pointer" }}
              gapSize
            >
              <input
                type="radio"
                checked={hasInsurance}
                onChange={() => {}}
              />
              <Typography
                variant={"h6"}
                fontWeight={400}
                color={"terceary"}
              >
                Sim
              </Typography>
            </Flex>
            <Flex
              onClick={onClickHasntInsuranceRadioButton}
              style={{ cursor: "pointer" }}
              gapSize
            >
              <input
                type="radio"
                checked={!hasInsurance}
                onChange={() => {}}
              />
              <Typography
                variant={"h6"}
                fontWeight={400}
                color={"terceary"}
              >
                Não
              </Typography>
            </Flex>
            {hasInsurance && (
              <>
                <Typography
                  variant="subheading1"
                  fontWeight={700}
                  color={"terceary"}
                >
                  Carteirinha do plano de saúde recente
                </Typography>
                <UploadFile
                  archiveName={"health-id"}
                  title={"Selecione o arquivo ou arraste e solte aqui"}
                  subtitle={"JPG, PNG ou PDF, arquivos de até 5MB."}
                  presignedUrl={presignedUrl}
                  onUploadFile={handleUploadDocument}
                  isUploaded={isUploaded(documentsForm, "health-id")}
                />
              </>
            )}
          </Flex>
        </Flex>
        <Button
          text={"Continuar"}
          onClick={onClickConfirm}
          width={"163px"}
          height={"30px"}
          color={"rgba(89, 0, 204, 1)"}
          backgroundColor={'white'}
          disabled={isLoading || hasErrors}
        />
      </Flex>
    </Flex>
  )
}

export default DocumentsUploadPageRoute;