import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { signUpUseCase } from "../useCases/mutations/signUpMutation";
import { setFromLS } from "../../../../utils/localStorage";
import { getErrorMessage } from "../../../../utils/parser";
import { trackPage, trackAction } from "../../../../utils/segment";

export const emailPageModel = () => {
  const navigate = useNavigate();
  const [emailInput, setEmailIinput] = useState()
  const [pswdInput, setPswdInput] = useState();
  const [errorMessage, setErrorMessage] = useState('')
  const [isValidPassword, setIsValidPassword] = useState(false);
  trackPage("plg-signup-email");
  const {
    mutateSignUp,
    dataSignUpUseCase,
    isLoadingSignUpUseCase,
    isSuccessSignUpUseCase,
    errorSignUpUseCase,
  } = signUpUseCase();
  const onChangePswrdInput = (value) => {
    setPswdInput(value);
  }

  const onChangeEmailInput = (value) => {
    setEmailIinput(value);
  }
  const onClickConfirm = () => {
    trackAction("plg-signup-email-button");
    mutateSignUp({
      email: emailInput,
      password: pswdInput,
    });
  }

  useEffect(() => {
    if (!isLoadingSignUpUseCase && isSuccessSignUpUseCase) {
      setErrorMessage('')
      setFromLS('user', dataSignUpUseCase)
      navigate('/signup/confirmation', {
        state: {
          email: emailInput,
          password: pswdInput,
        },
      })
    }
    if(errorSignUpUseCase) {
      const error = getErrorMessage(errorSignUpUseCase);
      setErrorMessage(error);
    }
  }, [
    isLoadingSignUpUseCase,
    isSuccessSignUpUseCase,
    errorSignUpUseCase,
  ])

  return {
    onClickConfirm,
    emailInput,
    pswdInput,
    onChangePswrdInput,
    onChangeEmailInput,
    isLoading: isLoadingSignUpUseCase,
    errorMessage,
    setIsValidPassword,
    isValidPassword,
  }
}
