import './App.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import AppRoutes from './AppRoutes';
import { FormsProvider } from './contexts/FormContext/FormContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from './utils/authService';
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
});

const queryClient = new QueryClient()

const App = () => {
  return (
    <div style={{
      display: 'flex',
      height: '100%',
    }}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CREDENTIAL_API}>
        <FormsProvider>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <AppRoutes/>
            </AuthProvider>
          </QueryClientProvider>
        </FormsProvider>
      </GoogleOAuthProvider>
    </div>
  )
}

export default App;
