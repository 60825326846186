import { useNavigate } from "react-router-dom";

export const dependentHealthDeclarationPageModel = () => {
  const navigate = useNavigate();

  const onClickConfirm = () => {
    navigate('/contract/dependents/documents-upload');
  }

  return {
    onClickConfirm,
  }
}
