import { trackAction, trackPage } from "../../../../utils/segment";

export const contractSuccessPageModel = () => {
  trackPage("plg-contract-success");

  const onClickSuccessButton = () => {
    trackAction("plg-contract-success-referal-button")
    const message = `Olha só o que eu encontrei! Conheça os melhores planos de saúde.
      simular.ducasaude.com.br`;
    const url = `https://api.whatsapp.com/send?text=${encodeURI(message)}&app_absent=0`;
    window.open(url);
  }

  return {
    onClickSuccessButton,
  }
}
