import axios from "axios";
import { useMutation } from 'react-query';
import { API } from "../../../../../../utils/http";
import { getAccessToken } from "../../../../../../utils/localStorage";

async function updateDependentMutation({
  name,
  cpf,
  email,
  cellphone,
  civilStatus,
  occupation,
  contractId,
  signerType,
  simulationDependentId,
  height,
  weight,
}) {
  const response = await axios.post(
    `${API}/contracts/${contractId}/signer`,
    {
      name,
      cpf,
      email,
      cellphone,
      civilStatus,
      occupation,
      signerType,
      simulationDependentId,
      height,
      weight,
    },
    {
      headers: {
        Authorization: getAccessToken(),
      },
    },
  );
  return response.data;
}

export const updateDependentUseCase = () => {
  const {
    isSuccess,
    data,
    isLoading,
    error,
    mutate,
  } = useMutation(
    (e) => updateDependentMutation(e),
  );

  return {
    isSuccessUpdateDependentCase: isSuccess,
    dataUpdateDependentCase: data,
    isLoadingUpdateDependentCase: isLoading,
    errorUpdateDependentCase: error,
    mutateUpdateDependent: mutate,
  }
}
