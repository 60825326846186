import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Flex from '../atoms/Flex/Flex';
import { FaArrowLeft } from 'react-icons/fa';
import ducaLogo from '../../assets/duca.png';

const Menu = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Flex
      flexDirection="column"
      style={{
        width: '100%',
      }}
    >
      <Flex
        style={{
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'fixed',
          backgroundColor: 'white',
          left: 0,
          zIndex: 9999,
          height: "50px",
        }}
      >
        {location.pathname !== '/' ? (
          <FaArrowLeft
            color="rgba(89, 0, 204, 1)"
            size={16}
            onClick={() => navigate(-1)}
            style={{ cursor: "pointer", position: 'absolute', left: 20 }}
            aria-label="Go back"
          />
        ) : null}
        <img src={ducaLogo} style={{ maxWidth: 150 }} alt="Duca logo"/>
      </Flex>
      <Outlet />
    </Flex>
  );
};

export default Menu;
