import { useLocation, useNavigate } from "react-router-dom";
import { verifyLoginUseCase } from "../useCases/mutations/verifyLoginMutation";
import { resentTokenUseCase } from "../useCases/mutations/resendTokenMutation";

import { useEffect, useState } from "react";
import { getFromLS, setAccessToken } from "../../../../utils/localStorage";
import { useAuth } from "../../../../utils/authService";
import { trackAction, trackPage } from "../../../../utils/segment";

export const confirmationPageModel = () => {
  const navigate = useNavigate();
  const { login } = useAuth()
  const { state } = useLocation();
  const [tokenInput, setTokenInput] = useState();
  trackPage("plg-signup-token-confirmation")
  const {
    isLoadingVerifyLoginUseCase,
    isSuccessVerifyLoginUseCase,
    dataVerifyLoginUseCase,
    mutateVerifyLogin,
  } = verifyLoginUseCase();

  const {
    mutateResendToken,
  } = resentTokenUseCase();

  const onChangeTokenInput = (value) => {
    setTokenInput(value);
  }

  const onClickResendToken = () => {
    mutateResendToken({
      email: state.email,
    })
  }

  const onClickConfirm = () => {
    trackAction("plg-signup-token-confirmation-button");
    const user = getFromLS("user")
    mutateVerifyLogin({
      userId: user.id,
      token: tokenInput,
      password: state.password,
    })
  }

  useEffect(() => {
    if(!isLoadingVerifyLoginUseCase && isSuccessVerifyLoginUseCase) {
      setAccessToken(dataVerifyLoginUseCase);
      login(dataVerifyLoginUseCase);
      navigate('/signup/success');
    }
  }, [isLoadingVerifyLoginUseCase, isSuccessVerifyLoginUseCase])

  return {
    onClickResendToken,
    onChangeTokenInput,
    onClickConfirm,
    tokenInput,
    isLoading: isLoadingVerifyLoginUseCase,
  }
}
