import Flex from "../../../../components/atoms/Flex/Flex";
import Typography from "../../../../components/atoms/Typography/Typography";
import Loading from "../../../../components/molecules/Loading/Loading";
import DependentContractCard from "./components/DependentContractCard";
import { dependentsHomePageModel } from "./viewModel/dependentsHomePageModel";

const DependentsHomePageRoute = () => {
  const {
    onClickDependentCard,
    dependents,
    isLoading,
    getDependentName,
    calculateDependentProgress,
  } = dependentsHomePageModel();
  return (
    <Flex
      flexDirection={"column"}
      alignItems={"center"}
      style={{
        height: "100%",
        gap: "12px",
        marginTop: 120,
      }}
    >
      {isLoading && (<Loading/>)}
      <Flex
        flexDirection={"column"}
        style={{
          marginLeft: 20,
          marginRight: 20,
          marginBottom: 60,
        }}
      >
        <Typography
          variant="h4"
          fontWeight={200}
          color={"terceary"}
        >
          Dependentes
        </Typography>
        <Typography
          variant={"subheading2"}
          fontWeight={400}
          color={"terceary"}
          style={{
            marginTop: 12,
            alignText: "justify",
          }}
        >
          Preencha os dados referentes a cada um dos dependentes
        </Typography>
      </Flex>
      {dependents.map((dependent, i) => (
        <DependentContractCard
          key={dependent.id}
          dependentName={getDependentName(dependent.id)}
          title={`Dependente - ${i + 1}`}
          subtitle={`${dependent.age} anos`}
          onClick={() => onClickDependentCard(dependent.id)}
          progress={calculateDependentProgress(dependent.id)}
        />
      ))}
    </Flex>
  )
}

export default DependentsHomePageRoute;