import { createContext, useContext } from 'react';
import { getAccessToken, removeAccessToken, setAccessToken } from './localStorage';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const login = (token) => {
    setAccessToken(token);
  };

  const logout = () => {
    removeAccessToken();
  };

  const isAuthenticated = () => {
    const accessToken = getAccessToken();
    // Check if the accessToken is present and not expired
    if (!accessToken) {
      return false;
    }
    return !isTokenExpired(accessToken);
  };

  const isTokenExpired = (token) => {
    // Extract expiration time from the token
    const expirationTime = getTokenExpirationTime(token);
    // Check if the token is expired
    return expirationTime < Date.now() / 1000;
  };

  const getTokenExpirationTime = (token) => {
    // Decode the token to get its payload
    const payload = JSON.parse(atob(token.split('.')[1]));
    // Extract the expiration time from the payload
    return payload.exp;
  };

  const getToken = () => {
    return getAccessToken();
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, getToken }}>
      {children}
    </AuthContext.Provider>
  );
};
