import React, { createContext, useContext, useState } from 'react';

const FormContext = createContext();

export const FormsProvider = ({ children }) => {
  const [form, setForms] = useState({
    token: "",
    holder: {
      pagesCompleted: 0,
      totalPages: 1,
      legalResponsible: {
        isResponsible: false,
      },
    },
    dependents: {
      pagesCompleted: 0,
      totalPages: 1,
    },
    company: {
      pagesCompleted: 0,
      totalPages: 1,
    },
  });

  const updateForm = (newForm) => {
    setForms((prevForm) => ({
      ...prevForm,
      ...newForm,
    }));
  };

  return (
    <FormContext.Provider value={{ form, updateForm }}>
      {children}
    </FormContext.Provider>
  );
};

export const useForms = () => {
  return useContext(FormContext);
};
