import Flex from "../atoms/Flex/Flex";
import Typography from "../atoms/Typography/Typography";
const Button = ({
  text,
  onClick,
  width = "320px",
  height = "40px",
  color = "white",
  style,
  disabled = false,
}) => {
  return (
    <Flex
      onClick={disabled ? null : onClick}
      style={{
        cursor: disabled ? "not-allowed" : "pointer",
        borderWidth: "1.5px 1.5px",
        justifyContent: "center",
        gap: "10px",
        alignItems: "center",
        alignSelf: "center",
        textAlign: "center",
        width,
        height,
        borderRadius: "8px",
        backgroundColor: disabled ? "#D5D9F2" : "#5900CC",
        fontWeight: "700",
        padding: "10px 18px",
        color: disabled ? "#ffffff80" : color,
        marginBottom: 40,
        ...style,
      }}
    >
      <Typography
        variant="subheading1"
        fontWeight={600}
        style={{
          color: "white",
        }}
      >
        {text}
      </Typography>
    </Flex>
  );
};

export default Button;