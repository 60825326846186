import React, { useEffect, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import Typography from '../../../../components/atoms/Typography/Typography';
import Flex from '../../../../components/atoms/Flex/Flex';
import { isMobile } from 'react-device-detect';
import { IoIosCloseCircleOutline } from "react-icons/io";

const PasswordErrorMessages = ({
  password,
  setIsValidPassword,
}) => {
  const [validations, setValidations] = useState({
    length: false,
    lowercase: false,
    uppercase: false,
    digit: false,
    specialChar: false,
  });

  const handleChange = (value) => {
    const isValid = validatePassword(value);
    setIsValidPassword(isValid);
  };

  const validatePassword = (password = '') => {
    // At least 8 characters
    const length = password.length >= 8;

    // At least one lowercase letter
    const lowercase = /[a-z]/.test(password);

    // At least one uppercase letter
    const uppercase = /[A-Z]/.test(password);

    // At least one digit
    const digit = /\d/.test(password);

    // At least one special character
    const specialChar = /[!@#$%^&*(),.?":{}|<>\\-]/.test(password);

    setValidations({ length, lowercase, uppercase, digit, specialChar });
    return length && lowercase && uppercase && digit && specialChar;
  };

  useEffect(() => {
    handleChange(password);
  }, [password])

  return (
    <Flex
      flexDirection={"column"}
      gapSize={"sm"}
      style={{
        marginTop: 24,
        ...(isMobile && {
          width: "85%",
        }),
      }}
    >
      <Typography
        variant={"subheading2"}
        fontWeight={600}
        color={"terceary"}
      >
        Sua senha deve conter:
      </Typography>
      <Flex gapSize>
        {validations.length ? (
          <FaCheckCircle size={20} style={{ color: '#00BF8F' }} />
        ) : (
          <IoIosCloseCircleOutline size={20} style={{ color: '#FF2D20' }} />
        )}{' '}
        <Typography
          variant="subheading2"
          fontWeight={400}
          color={"secondary"}
        >
          Pelo menos 8 caracteres
        </Typography>
      </Flex>
      <Flex gapSize>
        {validations.lowercase ? (
          <FaCheckCircle size={20} style={{ color: '#00BF8F' }} />
        ) : (
          <IoIosCloseCircleOutline size={20} style={{ color: '#FF2D20' }} />
        )}{' '}
        <Typography
          variant="subheading2"
          fontWeight={400}
          color={"secondary"}
        >
          Pelo menos uma letra minúscula
        </Typography>
      </Flex>
      <Flex gapSize>
        {validations.uppercase ? (
          <FaCheckCircle size={20} style={{ color: '#00BF8F' }} />
        ) : (
          <IoIosCloseCircleOutline size={20} style={{ color: '#FF2D20' }} />
        )}{' '}
        <Typography
          variant="subheading2"
          fontWeight={400}
          color={"secondary"}
        >
          Pelo menos uma letra maiúscula
        </Typography>
      </Flex>
      <Flex gapSize>
        {validations.digit ? (
          <FaCheckCircle size={20} style={{ color: '#00BF8F' }} />
        ) : (
          <IoIosCloseCircleOutline size={20} style={{ color: '#FF2D20' }} />
        )}{' '}
        <Typography
          variant="subheading2"
          fontWeight={400}
          color={"secondary"}
        >
          Pelo menos um dígito
        </Typography>
      </Flex>
      <Flex gapSize>
        {validations.specialChar ? (
          <FaCheckCircle size={20} style={{ color: '#00BF8F' }} />
        ) : (
          <IoIosCloseCircleOutline size={20} style={{ color: '#FF2D20' }} />
        )}{' '}
        <Typography
          variant="subheading2"
          fontWeight={400}
          color={"secondary"}
        >
          Pelo menos um caractere especial
        </Typography>
      </Flex>
    </Flex>
  );
};

export default PasswordErrorMessages;
