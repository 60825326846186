const STORAGE_KEY = 'accessToken';

export const setFromLS = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error('Error setting item in local storage:', error);
  }
};

export const getFromLS = (key) => {
  try {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : null;
  } catch (error) {
    console.error('Error getting item from local storage:', error);
    return null;
  }
};

export const removeFromLS = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error('Error removing item from local storage:', error);
  }
};

export const setAccessToken = (token) => {
  localStorage.setItem(STORAGE_KEY, token);
}

export const getAccessToken = () => {
  return localStorage.getItem(STORAGE_KEY);
};

export const removeAccessToken = () => {
  localStorage.removeItem(STORAGE_KEY);
};
