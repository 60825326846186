import { useNavigate } from "react-router-dom";

export const healthDeclarationPageModel = () => {
  const navigate = useNavigate();

  const onClickConfirm = () => {
    navigate('/contract/holder/documents-upload');
  }

  return {
    onClickConfirm,
  }
}
