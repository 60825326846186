import axios from "axios";
import { useMutation } from 'react-query';
import { API } from "../../../../utils/http";
import { getAccessToken } from "../../../../utils/localStorage";

async function updateIndividualMutation({ cpf, documents }) {
  const response = await axios.post(
    `${API}/individuals`,
    {
      cpf,
      documents,
    },
    {
      headers: {
        Authorization: getAccessToken(),
      },
    },
  );
  return response.data;
}

export const updateIndividualUseCase = () => {
  const {
    isSuccess,
    data,
    isLoading,
    error,
    mutate,
  } = useMutation(
    (e) => updateIndividualMutation(e),
  );

  return {
    isSuccessUpdateIndividualUseCase: isSuccess,
    dataUpdateIndividualUseCase: data,
    isLoadingUpdateIndividualUseCase: isLoading,
    errorUpdateIndividualUseCase: error,
    mutateUpdateIndividual: mutate,
  }
}
