import axios from "axios";
import { useQuery } from 'react-query';
import { API } from "../../../../utils/http";
import { getAccessToken, removeAccessToken } from "../../../../utils/localStorage";
import { getErrorMessage } from "../../../../utils/parser";
import { useNavigate } from 'react-router-dom';
async function getContractById() {
  const response = await axios.get(
    `${API}/contracts/userId`,
    {
      headers: {
        Authorization: getAccessToken(),
      },
    },
  );
  return response.data;
}

export const getContractByIdQuery = () => {
  const navigate = useNavigate();
  const {
    data,
    isLoading,
    isSuccess,
    error,
  } = useQuery(
    "getContractByIdQuery",
    () => getContractById(),
    {
      refetchOnWindowFocus: true,
      staleTime: 0,
      cacheTime: 0,
      refetchInterval: 0,
      retry: false,
      onError: (e) => {
        const error = getErrorMessage(e)
        if (error === "Não autorizado") {
          removeAccessToken();
          navigate('/');
        }
      },
    },
  );

  return {
    isSuccessContractQuery: isSuccess,
    dataContractQuery: data,
    isLoadingContractQuery: isLoading,
    errorContractQuery: error,
  }
}