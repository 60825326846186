import axios from "axios";
import { useMutation } from 'react-query';
import { API } from "../../../../../utils/http";

async function resendTokenMutation({ email }) {
  const response = await axios.post(
    `${API}/users/resend-token`,
    { email },
  );
  return response.data;
}

export const resentTokenUseCase = () => {
  const {
    isSuccess,
    data,
    isLoading,
    error,
    mutate,
  } = useMutation(
    (e) => resendTokenMutation(e),
  );

  return {
    isSuccessResendTokenUseCase: isSuccess,
    dataResendTokenUseCase: data,
    isLoadingResendTokenUseCase: isLoading,
    errorResendTokenUseCase: error,
    mutateResendToken: mutate,
  }
}
