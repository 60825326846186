import Flex from "../../../components/atoms/Flex/Flex";
import Typography from "../../../components/atoms/Typography/Typography";
import Button from "../../../components/molecules/Button"
import { confirmationPageModel } from "./viewModel/confirmationPageModel";
import Input from "../../../components/molecules/Input";
import ResendTokenLink from "./components/ResendTokenLink"
import Loading from "../../../components/molecules/Loading/Loading";
const ConfirmationPageRoute = () => {
  const { 
    onClickConfirm,
    onChangeTokenInput,
    tokenInput,
    onClickResendToken,
    isLoading,
  } = confirmationPageModel();
  return (
    <Flex
      flexDirection={"column"}
      justifyContent={"space-around"}
      alignItems={"center"}
      style={{
        height: "100%",
        gap: "12px",
      }}
    >
      {isLoading && (<Loading/>)}
      <Flex
        flexDirection={"column"}
        alignItems={"center"}
        style={{ gap: "24px" }}
      >
        <Typography
          variant="h4"
          fontWeight={200}
          color={"terceary"}
          style={{
            marginLeft: 50,
            marginRight: 50,
          }}
        >
          Insira o código de verificação 
        </Typography>
        <Typography
          variant="subheading1"
          fontWeight={200}
          color={"terceary"}
          style={{
            marginLeft: 50,
            marginRight: 50,
          }}
        >
          Enviado para o e-mail cadastrado
        </Typography>
        <Flex
          flexDirection={"column"}
          gapSize
          style={{ cursor: "pointer" }}
        >
          <Input
            placeholder={"Token"}
            type={"text"}
            defaultValue={tokenInput}
            onChange={(e) => onChangeTokenInput(e.target.value)}
          />
          <ResendTokenLink
            onClick={onClickResendToken}
          />
        </Flex>
      </Flex>
      <Button
        text={"Verificar"}
        onClick={onClickConfirm}
        width={"250px"}
        height={"20px"}
        color={"rgba(89, 0, 204, 1)"}
        backgroundColor={'white'}
        style={{
          borderColor: "rgba(89, 0, 204, 1)",
          border: "solid rgba(89, 0, 204, 1)",
          borderWidth: "1.5px 1.5px",
        }}
      />
    </Flex>
  )
}

export default ConfirmationPageRoute;