import axios from "axios";
import { useMutation } from 'react-query';
import { API } from "../../../../../../../utils/http";
import { getAccessToken } from "../../../../../../../utils/localStorage";

async function updateLegalResponsibleMutation({
  cellphone,
  contractId,
  documents,
  email,
  rg,
}) {
  const response = await axios.post(
    `${API}/contracts/${contractId}/legal-responsible`,
    {
      rg,
      email,
      cellphone,
      documents,
    },
    {
      headers: {
        Authorization: getAccessToken(),
      },
    },
  );
  return response.data;
}

export const updateLegalResponsibleUseCase = () => {
  const {
    isSuccess,
    data,
    isLoading,
    error,
    mutate,
  } = useMutation(
    (e) => updateLegalResponsibleMutation(e),
  );

  return {
    isSuccessUpdateLegalResponsibleCase: isSuccess,
    dataUpdateLegalResponsibleCase: data,
    isLoadingUpdateLegalResponsibleCase: isLoading,
    errorUpdateLegalResponsibleCase: error,
    mutateUpdateLegalResponsible: mutate,
  }
}
