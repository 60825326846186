import Flex from "../../../../../components/atoms/Flex/Flex";
import Typography from "../../../../../components/atoms/Typography/Typography";
import Button from "../../../../../components/molecules/Button";
import { legalResponsiblePageModel } from "./viewModel/legalResponsiblePageModel";
import Loading from "../../../../../components/molecules/Loading/Loading"
import Stepper from "../../../../../components/molecules/Stepper";
const LegalResponsiblePageRoute = () => {
  const {
    onClickConfirm,
    isLegalResponsible,
    onChangeInput,
    isLoading,
  } = legalResponsiblePageModel();
  return (
    <Flex flexDirection={"column"} style={{ marginTop: 90 }}>
      <Stepper
        size={4}
        activeStep={2}
      />
      <Flex
        flexDirection={"column"}
        justifyContent={"space-between"}
        alignItems={"center"}
        style={{
          height: "100%",
          gap: "12px",
          marginBottom: "40px",
        }}
      >
        {isLoading && (<Loading/>)}
        <Flex
          flexDirection={"column"}
          style={{
            marginTop: "20px",
          }}
        >
          <Flex
            flexDirection={"column"}
            style={{
              marginLeft: 50,
              marginRight: 50,
              marginBottom: 10,
            }}
          >
            <Typography
              variant="h4"
              fontWeight={200}
              color={"terceary"}
            >
              É a pessoa responsável legal pela empresa?
            </Typography>
            <Typography
              variant={"h6"}
              fontWeight={400}
              color={"terceary"}
              style={{
                marginTop: 12,
                alignText: "justify",
              }}
            >
              Solicitamos essa informação porque somente o responsável legal do 
              CNPJ pode assinar o contrato com a seguradora.
            </Typography>
          <Flex
            flexDirection={"column"}
            gapSize
            style={{
              marginTop: 36,
            }}
          >
            <Flex
              onClick={() => onChangeInput(true)}
              style={{ cursor: "pointer" }}
              gapSize
            >
              <input
                type="radio"
                checked={isLegalResponsible}
                onChange={() => {}}
              />
              <Typography
                variant={"h6"}
                fontWeight={400}
                color={"terceary"}
              >
                Sim, sou responsável legal pela empresa
              </Typography>
            </Flex>
            <Flex
              onClick={() => onChangeInput(false)}
              style={{ cursor: "pointer" }}
              gapSize
            >
              <input
                type="radio"
                checked={isLegalResponsible === undefined ? undefined : !isLegalResponsible}
                onChange={() => {}}
              />
              <Typography
                variant={"h6"}
                fontWeight={400}
                color={"terceary"}
              >
                Não, não sou responsável legal pela empresa
              </Typography>
            </Flex>
          </Flex>
          </Flex>
        </Flex>
        <Button
          text={"Continuar"}
          onClick={onClickConfirm}
          width={"163px"}
          height={"30px"}
          color={"rgba(89, 0, 204, 1)"}
          backgroundColor={'white'}
          disabled={isLegalResponsible === undefined}
        />
      </Flex>
    </Flex>
  )
}

export default LegalResponsiblePageRoute;