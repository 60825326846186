import Flex from "../../../../components/atoms/Flex/Flex";
import Typography from "../../../../components/atoms/Typography/Typography";
import Button from "../../../../components/molecules/Button";
import Input from "../../../../components/molecules/Input";
import { holderPageModel } from "./viewModel/holderPageModel"
import { cpfMask, heightMask, onlyNumbers, phoneMask } from "../../../../utils/masks"
import Loading from "../../../../components/molecules/Loading/Loading"
import Stepper from "../../../../components/molecules/Stepper";
import SearchableSelect from "../../../../components/molecules/SearchableSelect";
const HolderPageRoute = () => {
  const {
    onClickConfirm,
    onChangeInput,
    holderForm,
    isLoading,
    hasErrors,
    errors,
  } = holderPageModel();
  return (
    <Flex flexDirection={"column"} style={{ marginTop: 90 }}>
      <Stepper
        size={4}
        activeStep={1}
      />
      <Flex
        flexDirection={"column"}
        justifyContent={"space-between"}
        alignItems={"center"}
        style={{
          height: "100%",
          gap: "40px",
          marginTop: "20px",
        }}
      >
        {isLoading && (<Loading/>)}
        <Typography
            variant="h4"
            fontWeight={200}
            color={"terceary"}
            style={{
              marginLeft: 50,
              marginRight: 50,
            }}
          >
            Dados do titular
          </Typography>
        <Flex
          flexDirection={"column"}
          gapSize
        >
          <Flex
            flexDirection={"column"}
            style={{
              gap: "30px",
            }}
          >
            <Input
              placeholder={"Nome Completo"}
              type={"text"}
              defaultValue={holderForm.name}
              onChange={(e) => onChangeInput('name', e.target.value)}
              hasError={!!errors.name}
            />
            <Input
              placeholder={"CPF"}
              type={"tel"}
              defaultValue={cpfMask(holderForm.cpf)}
              onChange={(e) => onChangeInput('cpf', e.target.value)}
              hasError={!!errors.cpf}
              errorText={errors.cpf}
            />
            <Input
              placeholder={"E-mail"}
              type={"text"}
              defaultValue={holderForm.email}
              onChange={(e) => onChangeInput('email', e.target.value)}
              hasError={!!errors.email}
              errorText={errors.email}
            />
            <Input
              placeholder={"Telefone"}
              type={"tel"}
              defaultValue={phoneMask(holderForm.cellphone)}
              onChange={(e) => onChangeInput('cellphone', e.target.value)}
              hasError={!!errors.cellphone}
            />
            <SearchableSelect
              data={[
                "Solteiro",
                "Casado",
                "Divorciado",
                "Viúvo",
                "União Estável",
              ]}
              currentValue={holderForm.civilStatus}
              onSelect={(value) => onChangeInput('civilStatus', value)}
              onClear={() => {}}
              isLoading={false}
              placeholder={"Estado civil"}
              multi={false}
            />
            <Input
              placeholder={"Profissão"}
              type={"text"}
              defaultValue={holderForm.occupation}
              onChange={(e) => onChangeInput('occupation', e.target.value)}
              hasError={!!errors.occupation}
            />
            <Input
              placeholder={"Altura"}
              type={"tel"}
              defaultValue={heightMask(holderForm.height)}
              onChange={(e) => onChangeInput('height', e.target.value)}
              hasError={!!errors.height}
            />
            <Input
              placeholder={"Peso"}
              type={"tel"}
              defaultValue={onlyNumbers(holderForm.weight)}
              onChange={(e) => onChangeInput('weight', e.target.value)}
              hasError={!!errors.weight}
            />
          </Flex>
        </Flex>
        <Button
          text={"Continuar"}
          onClick={onClickConfirm}
          width={"285px"}
          height={"25px"}
          color={"rgba(89, 0, 204, 1)"}
          backgroundColor={'white'}
          disabled={hasErrors || isLoading}
        />
      </Flex>
    </Flex>
  )
}

export default HolderPageRoute;