
import axios from "axios";
import { useMutation } from 'react-query';
import { getAccessToken } from "../../../../../utils/localStorage";
import { API } from "../../../../../utils/http";

async function updateCompanyContractMutation({
  contractId,
  cnpj,
  documents,
  isMEI,
  socialContractUpdatedAt,
}) {
  const response = await axios.post(
    `${API}/contracts/${contractId}/company`,
    {
      cnpj,
      documents,
      isMEI,
      socialContractUpdatedAt,
    },
    {
      headers: {
        Authorization: getAccessToken(),
      },
    },
  );
  return response.data;
}

export const updateCompanyContractUseCase = () => {
  const {
    isSuccess,
    data,
    isLoading,
    error,
    mutate,
  } = useMutation(
    (e) => updateCompanyContractMutation(e),
  );

  return {
    isSuccessUpdateCompanyContractCase: isSuccess,
    dataUpdateCompanyContractCase: data,
    isLoadingUpdateCompanyContractCase: isLoading,
    errorUpdateCompanyContractCase: error,
    mutateUpdateCompanyContract: mutate,
  }
}
