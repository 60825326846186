import axios from "axios";
import { useMutation } from 'react-query';

async function putObjectToBucketMutation({ url, file }) {
  const options = {
    headers: {
      'Content-Type': file.type,
    },
  };

  const response = await axios.put(url, file, options);
  return response.data;
}

export const putObjectToBucket = () => {
  const {
    isSuccess,
    data,
    isLoading,
    error,
    mutate,
  } = useMutation(
    (e) => putObjectToBucketMutation(e),
  );

  return {
    isSuccessPutObjectToBucketUseCase: isSuccess,
    dataPutObjectToBucketUseCase: data,
    isLoadingPutObjectToBucketUseCase: isLoading,
    errorPutObjectToBucketUseCase: error,
    fetchPutObjectToBucket: mutate,
  }
}
