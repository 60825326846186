import { FaChevronCircleRight } from "react-icons/fa";
import Flex from "../../../../../components/atoms/Flex/Flex";
import Typography from "../../../../../components/atoms/Typography/Typography";
import { isMobile } from "react-device-detect";

const DependentContractCard = ({
  title,
  subtitle,
  onClick,
  progress,
  dependentName,
}) => {
  return (
    <Flex
      onClick={onClick}
      flexDirection={"column"}
      justifyContent={"space-around"}
      alignItems={"center"}
      style={{
        width: isMobile ? "70%" : 327,
        height: 100,
        border: "2px solid #D5D9F2",
        borderRadius: "5px",    
        padding: 24,
        cursor: "pointer",
      }}
    >
      <Flex
        justifyContent={"space-between"}
        gapSize
        style={{
          width: "100%",
        }}
      >
        <Typography
          variant="subheading1"
          fontWeight={500}
          style={{ color: "#181059" }}
        >
          {dependentName || title}
        </Typography>
        <FaChevronCircleRight
          size={24}
          onClick={() => {}}
          style={{
            cursor: "pointer",
          }}
          color="darkgray"
        /> 
      </Flex>
      <Typography
        variant="subheading2"
        fontWeight={400}
        color={"terceary"}
        style={{ alignSelf: "start" }}
      >
        {subtitle}
      </Typography>
      <Flex
        justifyContent={"space-between"}
        style={{ width: "100%" }}
      >
        <progress
          value={progress}
          style={{
            accentColor: "#5900CC",
            width: "75%",
          }}
        />
          <Typography
            variant="subheading2"
            fontWeight={400}
            color={"terceary"}
          >
            {progress * 100}%
          </Typography>
      </Flex>
    </Flex>
  )
}

export default DependentContractCard;