import axios from "axios";
import { useMutation } from 'react-query';
import { API } from "../../../../../utils/http";
import { getAccessToken } from "../../../../../utils/localStorage";

async function createContractMutation({ simulationId, userId }) {
  const response = await axios.post(
    `${API}/contracts`,
    {
      simulationId,
      userId,
    },
    {
      headers: {
        Authorization: getAccessToken(),
      },
    },
  );
  return response.data;
}

export const createContractUseCase = () => {
  const {
    isSuccess,
    data,
    isLoading,
    error,
    mutate,
  } = useMutation(
    (e) => createContractMutation(e),
  );

  return {
    isSuccessCreateContractUseCase: isSuccess,
    dataCreateContractUseCase: data,
    isLoadingCreateContractUseCase: isLoading,
    errorCreateContractUseCase: error,
    mutateCreateContract: mutate,
  }
}
