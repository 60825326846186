import { useNavigate, useParams } from "react-router-dom";
import { getFromLS } from "../../../../../utils/localStorage";
import { updateIndividualUseCase } from "../../../useCases/mutations/updateIndividualMutation";
import { getPresignedUrl } from "../../../../../utils/aws/get-presigned-url-mutation";
import { putObjectToBucket } from "../../../../../utils/aws/put-object-to-bucket";
import { useState } from "react";
import { useEffect } from "react";
import { getContractByIdQuery } from "../../../useCases/queries/getContractByIdQuery";
import { trackPage } from "../../../../../utils/segment";

export const dependentDocumentsUploadPageModel = () => {
  const navigate = useNavigate();
  const [hasInsurance, setHasInsurance] = useState(false);
  const [fileSelected, setFileSelected] = useState({});
  const [dependentForm, updateDependentForm] = useState([]);
  const [errors, setErrors] = useState({
    "health-id": true,
    bondWithHolder: true,
    document: true,
  });
  const contract = getFromLS("contract");
  trackPage("plg-contract-dependent-documents-upload");

  const { dependentId } = useParams();
  const {
    dataContractQuery,
    isLoadingContractQuery,
    isSuccessContractQuery,
  } = getContractByIdQuery()

  const {
    isLoadingUpdateIndividualUseCase,
    isSuccessUpdateIndividualUseCase,
    mutateUpdateIndividual,
  } = updateIndividualUseCase();

  const {
    dataPresignedUrlUseCase,
    isLoadingPresignedUrlUseCase,
    isSuccessPresignedUrlUseCase,
    fetchGetPresignedUrl,
  } = getPresignedUrl();
  
  const {
    fetchPutObjectToBucket,
    isLoadingPutObjectToBucketUseCase,
    isSuccessPutObjectToBucketUseCase,
  } = putObjectToBucket();

  const onClickConfirm = () => {
    const dependent = dataContractQuery.signers?.find(signer =>
      signer.simulationDependentId === dependentId,
    );
    mutateUpdateIndividual({
      cpf: dependent.cpf,
      documents: dependentForm,
    })
  }

  const handleUploadDocument = (archiveName, file) => {
    const splitName = file.path.split('.') || []
    const type = splitName[splitName.length - 1]
    setFileSelected(() => ({archiveName, file}));
    fetchGetPresignedUrl({
      contentType: file.type,
      path: `contracts/${contract.id}/dependents/${dependentId}/${archiveName}.${type}`,
    })
  }

  const validateInput = (inputName, value) => {
    if (!value.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [inputName]: `${inputName} is required.`,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [inputName]: null,
      }));
    }
  };

  const hasErrors = Object.entries(errors).some(([errorName, value]) => {
    if (errorName === 'health-id') {
      return hasInsurance ? value : null
    }
    return value;
  });

  const onClickHasInsuranceRadioButton = () => {
    setHasInsurance(true)
  };

  const onClickHasntInsuranceRadioButton = () => {
    setHasInsurance(false)
  };

  const isUploaded = (documents, documentType) => {
    return documents.some(document => document.type === documentType)
  }

  useEffect(() => {
    if (!isLoadingPresignedUrlUseCase && isSuccessPresignedUrlUseCase) {
      fetchPutObjectToBucket({
        url: dataPresignedUrlUseCase,
        file: fileSelected.file,
      })
    }
  }, [isLoadingPresignedUrlUseCase, isSuccessPresignedUrlUseCase])

  useEffect(() => {
    if(!isLoadingPutObjectToBucketUseCase && isSuccessPutObjectToBucketUseCase) {
      const splitName = fileSelected.file.path.split('.') || []
      const type = splitName[splitName.length - 1]
      const bucketLink = `contracts/${contract.id}/dependents/${dependentId}/${fileSelected.archiveName}.${type}`;
      updateDependentForm([...dependentForm, {
          type: fileSelected.archiveName,
          bucketLink,
        }],
      )
      validateInput(fileSelected.archiveName, bucketLink)
    }
  }, [isLoadingPutObjectToBucketUseCase, isSuccessPutObjectToBucketUseCase])

  useEffect(() => {
    if (!isLoadingContractQuery && isSuccessContractQuery) {
      const dependent = dataContractQuery.signers?.find(signer =>
        signer.simulationDependentId === dependentId,
      );
      updateDependentForm(dependent?.documents || [])
      dependent?.documents?.forEach(document => {
        validateInput(document.type, document.bucketLink)
      })
    }
  }, [isLoadingContractQuery, isSuccessContractQuery])

  useEffect(() => {
    if(!isLoadingUpdateIndividualUseCase && isSuccessUpdateIndividualUseCase) {
      navigate('/contract/home');
    }
  }, [isLoadingUpdateIndividualUseCase, isSuccessUpdateIndividualUseCase])

  return {
    onClickHasInsuranceRadioButton,
    onClickHasntInsuranceRadioButton,
    onClickConfirm,
    handleUploadDocument,
    presignedUrl: dataPresignedUrlUseCase,
    hasInsurance,
    isLoading: isLoadingUpdateIndividualUseCase || isLoadingContractQuery || isLoadingPutObjectToBucketUseCase,
    isUploaded,
    documentsForm: dependentForm,
    hasErrors,
  }
}