import { useNavigate, useSearchParams } from "react-router-dom";
import { setFromLS } from "../../../utils/localStorage";
import { trackAction, trackPage } from "../../../utils/segment"

export const landingPageModel = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  trackPage("plg-main")
  const simulationId = searchParams.get('simulationId')
  setFromLS('simulationId', simulationId);

  const onClickLoginToAccount = () => {
    trackAction("plg-main-login-button")
    navigate(location.pathname + 'signin/email')
  }

  const onClickCreateAccount = () => {
    if (!simulationId) {
      trackAction("plg-main-create-account-without-simulation-button")
      navigate('/simulation/404')
      return
    }
    trackAction("plg-main-create-account-button")
    navigate(location.pathname + 'signup/email')
  }

  const redirectToPrivacyPage = (e) => {
    e.preventDefault();
    trackAction("plg-main-privacy-button")
    const url = `https://ducasaude.com.br/politica-de-privacidade`;
    window.open(url);
  }
  return {
    onClickLoginToAccount,
    onClickCreateAccount,
    redirectToPrivacyPage,
  }
}
