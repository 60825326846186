import Flex from "../../../components/atoms/Flex/Flex";
import Typography from "../../../components/atoms/Typography/Typography";
import Button from "../../../components/molecules/Button"
import { successPageModel } from "./viewModel/successPageModel";
import { ReactComponent as EmailSuccess } from "../../../assets/svg/EmailSuccess.svg"
import Loading from "../../../components/molecules/Loading/Loading"
const SuccessPageRoute = () => {
  const {
    onClickConfirm,
    isLoading,
  } = successPageModel();
  return (
    <Flex
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      style={{
        height: "100%",
        gap: "12px",
      }}
    >
      {isLoading && (<Loading/>)}
      <Typography
        variant="h4"
        fontWeight={200}
        color={"terceary"}
        style={{
          marginLeft: 50,
          marginRight: 50,
          marginTop: 90,
        }}
      >
        E-mail verificado com sucesso
      </Typography>
      <EmailSuccess />
      <Button
        text={"Continuar"}
        onClick={onClickConfirm}
        width={"250px"}
        height={"20px"}
        color={"rgba(89, 0, 204, 1)"}
        backgroundColor={'white'}
        style={{
          borderColor: "rgba(89, 0, 204, 1)",
          border: "solid rgba(89, 0, 204, 1)",
          borderWidth: "1.5px 1.5px",
        }}
      />
    </Flex>
  )
}

export default SuccessPageRoute;