import axios from "axios";
import { useMutation } from 'react-query';
import { API } from "../../../../../utils/http";

async function signUpMutation({ email, password }) {
  const response = await axios.post(
    `${API}/users/signup`,
    {
      email,
      password,
    },
  );
  return response.data;
}

export const signUpUseCase = () => {
  const {
    isSuccess,
    data,
    isLoading,
    error,
    mutate,
  } = useMutation(
    (e) => signUpMutation(e),
  );

  return {
    isSuccessSignUpUseCase: isSuccess,
    dataSignUpUseCase: data,
    isLoadingSignUpUseCase: isLoading,
    errorSignUpUseCase: error,
    mutateSignUp: mutate,
  }
}
