import { isMobile } from "react-device-detect";
import Flex from "../../../components/atoms/Flex/Flex";
import Typography from "../../../components/atoms/Typography/Typography";
import Button from "../../../components/molecules/Button";
import Input from "../../../components/molecules/Input";
import Loading from "../../../components/molecules/Loading/Loading";
import UploadFile from "../../../components/molecules/UploadFile";
import { cnpjMask } from "../../../utils/masks";
import { companyPageModel } from "./viewModel/companyPageModel";
import Stepper from "../../../components/molecules/Stepper";

const CompanyPageRoute = () => {
  const {
    onClickConfirm,
    onChangeInput,
    companyForm,
    onClickHasMEICompany,
    onClickHasntMEICompany,
    onUploadFile,
    presignedUrl,
    isLoading,
    isUploaded,
    hasErrors,
    errors,
  } = companyPageModel();

  return (
    <Flex flexDirection={"column"} style={{ marginTop: 90 }}>
      <Stepper
        size={1}
        activeStep={1}
      />
      <Flex
        flexDirection={"column"}
        justifyContent={"space-between"}
        alignItems={"center"}
        style={{
          height: "100%",
          gap: "12px",
          margin: 20,
        }}
      >
        {isLoading && (<Loading/>)}
        <Flex
          flexDirection={"column"}
          gapSize
          style={{
            marginTop: "20px",
            ...(!isMobile && {
              width: "50%",
            }),
          }}
        >
          <Flex
            flexDirection={"column"}
            style={{
              marginLeft: 20,
              marginRight: 20,
              marginBottom: 10,
            }}
          >
            <Typography
              variant="h4"
              fontWeight={200}
              color={"terceary"}
            >
              Dados da empresa
            </Typography>
            <Typography
              variant={"subheading2"}
              fontWeight={400}
              color={"terceary"}
              style={{
                marginTop: 12,
                alignText: "justify",
              }}
            >
              Os dados serão compartilhados com a seguradora escolhida, 
              que vai analisar se está em conformidade com as regras de contratação
            </Typography>
          </Flex>
          <Flex
            flexDirection={"column"}
            gapSize
            style={{
              margin: 20,
            }}
          >
            <Typography
              variant="subheading1"
              fontWeight={700}
              color={"terceary"}
            >
              A empresa é MEI?
            </Typography>
            <Flex
              onClick={onClickHasMEICompany}
              style={{ cursor: "pointer" }}
              gapSize
            >
              <input
                type="radio"
                checked={companyForm.isMEI}
                onChange={() => {}}
              />
              <Typography
                variant={"h6"}
                fontWeight={400}
                color={"terceary"}
              >
                Sim
              </Typography>
            </Flex>
            <Flex
              onClick={onClickHasntMEICompany}
              style={{ cursor: "pointer" }}
              gapSize
            >
              <input
                type="radio"
                checked={!companyForm.isMEI}
                onChange={() => {}}
              />
              <Typography
                variant={"h6"}
                fontWeight={400}
                color={"terceary"}
              >
                Não
              </Typography>
            </Flex>
            <Input
              placeholder={"CNPJ"}
              type={"tel"}
              defaultValue={cnpjMask(companyForm.cnpj)}
              onChange={(e) => onChangeInput('cnpj', e.target.value)}
              hasError={!!errors.cnpj}
              errorText={errors.cnpj}
            />
            {!companyForm.isMEI && (
              <>
                <Typography
                  variant="subheading1"
                  fontWeight={700}
                  color={"terceary"}
                  style={{ marginTop: 12 }}
                >
                  Última alteração do Contrato Social
                </Typography>
                <UploadFile
                  archiveName={"social-contract"}
                  title={"Selecione o arquivo ou arraste e solte aqui"}
                  subtitle={"JPG, PNG ou PDF, arquivos de até 5MB."}
                  onUploadFile={onUploadFile}
                  presignedUrl={presignedUrl}
                  isUploaded={isUploaded(companyForm.documents, "social-contract")}
                />
              </>
            )}
          </Flex>
        </Flex>
        <Button
          text={"Continuar"}
          onClick={onClickConfirm}
          width={"163px"}
          height={"30px"}
          color={"rgba(89, 0, 204, 1)"}
          backgroundColor={'white'}
          disabled={isLoading || hasErrors}
        />
      </Flex>
    </Flex>
  )
}

export default CompanyPageRoute;