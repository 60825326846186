import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getFromLS } from "../../../../utils/localStorage";
import { putObjectToBucket } from "../../../../utils/aws/put-object-to-bucket";
import { getPresignedUrl } from "../../../../utils/aws/get-presigned-url-mutation";
import { updateCompanyContractUseCase } from "../useCases/mutations/updateCompanyContract";
import { getContractByIdQuery } from "../../useCases/queries/getContractByIdQuery";
import { validateCNPJ } from "../../../../utils/parser";
import { trackPage } from "../../../../utils/segment";
export const companyPageModel = () => {
  const navigate = useNavigate();
  const contract = getFromLS("contract");
  const [fileSelected, setFileSelected] = useState();
  const [companyForm, updateCompanyForm] = useState({});
  const [errors, setErrors] = useState({
    cnpj: true,
    "social-contract": true,
  });
  trackPage("plg-contract-company");

  const {
    dataContractQuery,
    isLoadingContractQuery,
    isSuccessContractQuery,
  } = getContractByIdQuery()

  const {
    dataPresignedUrlUseCase,
    isLoadingPresignedUrlUseCase,
    isSuccessPresignedUrlUseCase,
    fetchGetPresignedUrl,
  } = getPresignedUrl();

  const {
    fetchPutObjectToBucket,
    isLoadingPutObjectToBucketUseCase,
    isSuccessPutObjectToBucketUseCase,
  } = putObjectToBucket();

  const {
    isLoadingUpdateCompanyContractCase,
    isSuccessUpdateCompanyContractCase,
    mutateUpdateCompanyContract,
  } = updateCompanyContractUseCase();

  const onClickConfirm = () => {
    mutateUpdateCompanyContract({
      cnpj: companyForm.cnpj,
      documents: companyForm.documents,
      isMEI: companyForm.isMEI,
      socialContractUpdatedAt: companyForm.socialContractUpdatedAt,
      contractId: contract.id,
    })
  }

  const onChangeInput = (inputName, value) => {
    validateInput(inputName, value)
    updateCompanyForm({
      ...companyForm,
      [inputName]: value,
    })
  }

  const validateInput = (inputName, value) => {
    if (!value.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [inputName]: `${inputName} é obrigatório.`,
      }));
    } else {
      if (inputName === "cnpj") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [inputName]: validateCNPJ(value) ? null : `Digite um CNPJ válido`,
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [inputName]: null,
        }));
      }
    }
  };
  const hasErrors = Object.entries(errors).some(([errorName, value]) => {
    if (errorName === 'social-contract') {
      return !companyForm?.isMEI ? value : null
    }
    return value;
  });

  const onClickHasMEICompany = () => {
    updateCompanyForm({
      ...companyForm,
      isMEI: true,
    })
  }

  const onClickHasntMEICompany = () => {
    updateCompanyForm({
      ...companyForm,
      isMEI: false,
    })
  }

  const handleUploadDocument = (archiveName, file) => {
    const splitName = file.path.split('.') || []
    const type = splitName[splitName.length - 1]
    setFileSelected({ file, archiveName });
    fetchGetPresignedUrl({
      path: `contracts/${contract.id}/company/${archiveName}.${type}`,
      contentType: file.type,
    })
  }

  const isUploaded = (documents, documentType) => {
    return documents?.some(document => document.type === documentType)
  }

  useEffect(() => {
    if (!isLoadingPresignedUrlUseCase && isSuccessPresignedUrlUseCase) {
      fetchPutObjectToBucket({
        url: dataPresignedUrlUseCase,
        file: fileSelected.file,
      })
    }
  }, [isLoadingPresignedUrlUseCase, isSuccessPresignedUrlUseCase])

  useEffect(() => {
    if(!isLoadingPutObjectToBucketUseCase && isSuccessPutObjectToBucketUseCase) {
      const splitName = fileSelected.file.path.split('.') || []
      const type = splitName[splitName.length - 1]
      const bucketLink = `contracts/${contract.id}/company/${fileSelected.archiveName}.${type}`;
      updateCompanyForm({
        ...companyForm,
        documents: [{
          type: fileSelected.archiveName,
          bucketLink,
        }],
      })
      validateInput(fileSelected.archiveName, bucketLink);
    }
  }, [isLoadingPutObjectToBucketUseCase, isSuccessPutObjectToBucketUseCase])
  
  useEffect(() => {
    if (!isLoadingContractQuery && isSuccessContractQuery) {
      if (dataContractQuery.company) {
        setErrors({
          cnpj: !(dataContractQuery.company?.cnpj),
          "social-contract": !(dataContractQuery.legalResponsible?.documents),
        })
        updateCompanyForm({
          cnpj: dataContractQuery.company?.cnpj ?? "",
          socialContractUpdatedAt: dataContractQuery.company?.socialContractUpdatedAt ?? "",
          documents: dataContractQuery.company?.documents ?? [],
          isMEI: dataContractQuery.company?.isMEI ?? false,
        })
      }
    }
  }, [isLoadingContractQuery, isSuccessContractQuery])

  useEffect(() => {
    if(!isLoadingUpdateCompanyContractCase && isSuccessUpdateCompanyContractCase) {
      navigate('/contract/home');
    }
  }, [isLoadingUpdateCompanyContractCase, isSuccessUpdateCompanyContractCase])
  
  return {
    onClickConfirm,
    onChangeInput,
    onClickHasMEICompany,
    onClickHasntMEICompany,
    companyForm,
    handleUploadDocument,
    onUploadFile: handleUploadDocument,
    presignedUrl: dataPresignedUrlUseCase,
    isLoading: isLoadingContractQuery || isLoadingUpdateCompanyContractCase,
    isUploaded,
    hasErrors,
    errors,
  }
}
