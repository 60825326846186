import Flex from "../../components/atoms/Flex/Flex";
import Typography from "../../components/atoms/Typography/Typography";
import { landingPageModel } from "./viewModel/landingPageModel";
import Button from "../../components/molecules/Button"
import { ReactComponent as LandingPageLogo } from '../../assets/svg/LandingPageLogo.svg';
import { isMobile } from "react-device-detect";

const LandingPageRoute = () => {
  const {
    onClickLoginToAccount,
    onClickCreateAccount,
  } = landingPageModel();
  return (
    <Flex
      flexDirection={"column"}
      justifyContent={isMobile ? "space-evenly" : "center"}
      alignItems={"center"}
      gapSize
      style={{
        height: "100%",
        gap: "12px",
        marginTop: 55,
        margin: 24,
      }}
    >
      <Flex
        flexDirection={"column"}
        gapSize={"lg"}
        alignItems={"center"}
      >
        <LandingPageLogo />
        <Typography
          variant="h5"
          fontWeight={400}
          color={"terceary"}
          style={{ textAlign: "center" }}
        >
          Estamos quase lá!
        </Typography>
        <Typography
          variant={"subheading2"}
          fontWeight={400}
          color={"terceary"}
          style={{
            marginTop: 12,
            textAlign: "center",
          }}
        >
          Você está a poucos passos de contratar seu plano de saúde 100% online, sem burocracia e sem dor de cabeça!
        </Typography>
      </Flex>
      <Flex
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        gapSize={"sm"}
      >
        <Typography
          variant={"subheading2"}
          fontWeight={600}
          color={"terceary"}
        >
          Para continuarmos, selecione uma das opções:
        </Typography>
        <Button
          text={"Criar conta"}
          onClick={onClickCreateAccount}
          width={"90%"}
          height={"20px"}
          color={"rgba(89, 0, 204, 1)"}
          backgroundColor={'white'}
          style={{
            borderColor: "rgba(89, 0, 204, 1)",
            border: "solid rgba(89, 0, 204, 1)",
            borderWidth: "1.5px 1.5px",
          }}
        />
        <Typography
          variant="subheading2"
          fontWeight={600}
          color={"terceary"}
        >
          ou
        </Typography>
        <Flex onClick={onClickLoginToAccount} style={{ cursor: "pointer" }}>
          <Typography
            variant="subheading1"
            fontWeight={700}
            color={"terceary"}
          >
            Entrar com e-mail
          </Typography>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default LandingPageRoute;