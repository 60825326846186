export const cnpjMask = (value) => {
  if (!value) return
  return value
    .replace(/\D+/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

export const cpfMask = value => {
  if (!value) return;

  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

export const dateMask = (value) => {
  if (!value) return;

  const cleanedValue = value.replace(/\D+/g, '');

  const formattedValue = cleanedValue
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\/\d{4})\d+?$/, '$1')

  return formattedValue;
};

export const phoneMask = (value) => {
  if (!value) return ""

  const formattedValue = value
    .replace(/\D/g,'')
    .replace(/(\d{2})(\d)/,"($1) $2")
    .replace(/(\d)(\d{4})$/,"$1-$2")
  return formattedValue
}

export const heightMask = value => {
  if (!value) return;

  const onlyDigits = value.replace(/\D/g, '');
  return onlyDigits.replace(/(\d+)(\d{2})$/, '$1,$2');
}

export const onlyNumbers = (value) => {
  if (!value) return
  return value.replace(/\D/g, '');
}

export const convertPriceToMoney = (cents) => {
  if (!cents) return `R$ 0`

  const price = cents / 100;
  return price.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
}