import { useNavigate } from "react-router-dom";
import { getContractByIdQuery } from "../../../useCases/queries/getContractByIdQuery";
import { trackPage } from "../../../../../utils/segment";

export const dependentsHomePageModel = () => {
  const navigate = useNavigate();
  trackPage("plg-contract-dependent-home");

  const {
    dataContractQuery,
    isLoadingContractQuery,
  } = getContractByIdQuery()

  const onClickDependentCard = (dependentId) => {
    navigate(`/contract/dependents/${dependentId}/personal-info`);
  }

  const getDependentName = (dependentId) => {
    const dependent = dataContractQuery.signers?.find(signer =>
      signer.simulationDependentId === dependentId,
    );
    if (!dependent) return ""

    return dependent.name;
  }

  const calculateDependentProgress = (dependentId) => {
    const dependentSigner = dataContractQuery?.signers?.find(
      signer => signer.simulationDependentId === dependentId,
    );

    if (!dependentSigner) return 0
    
    const dependentSum = Object.entries(dependentSigner).reduce((acc, [key, value]) => {
      if (
        key === "name" ||
        key === "cpf" ||
        key === "email" || 
        key === "cellphone" || 
        key === "civilStatus" || 
        key === "occupation" ||
        key === "weight" ||
        key === "height"
      ) return acc + 1;

      if (key === "documents") {
        return acc + (value?.length || 0);
      }
      return acc
    }, 0)

    const TOTAL_REQUIRED_FIELDS = 8
    const completedSum = dependentSum > 8 ? 8 : dependentSum
    return Math.floor((completedSum / TOTAL_REQUIRED_FIELDS) * 100) / 100
  }

  return {
    onClickDependentCard,
    dependents: dataContractQuery?.dependents || [],
    isLoading: isLoadingContractQuery,
    getDependentName,
    calculateDependentProgress,
  }
}
