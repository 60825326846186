import { isMobile } from "react-device-detect"
import Flex from "../../../components/atoms/Flex/Flex"
import Typography from "../../../components/atoms/Typography/Typography"
import FloatingButton from "../../../components/molecules/FloatingButton"
import { ReactComponent as NotFoundSimulationLogo } from '../../../assets/svg/NotFoundSimulation.svg';
import Button from "../../../components/molecules/Button";
import { notFoundSimulationModel } from "./viewModel/notFoundSimulationModel";

const NotFoundSimulation = () => {
  const {
    onClickGoToSmartQuotation,
    onClickHelp,
  } = notFoundSimulationModel()
  return (
    <Flex
      flexDirection={"column"}
      justifyContent={"space-around"}
      alignItems={"center"}
      style={{
        height: "100%",
        gap: isMobile ? "15px" : "120px",
        marginTop: 90,
        paddingBottom: 90,
      }}
    >
      <Flex
        flexDirection={"column"}
        alignItems={isMobile ? "": "center"}
        style={{
          width: isMobile ? "90%" : "690px",
          marginBottom: 10,
        }}
      >
        <Typography
          variant={isMobile ? "subheading1" : "h4"}
          fontWeight={600}
          color={"terceary"}
        >
          Muito bem-vindo(a)!
        </Typography>
        <Typography
          variant={"subheading2"}
          fontWeight={400}
          color={"secondary"}
          style={{
            marginTop: 12,
            alignText: "center",
          }}
        >
          Hummm parece que você ainda não fez uma simulação para escolher uma das seguradoras parceiras da Duca. 
          Clique no botão abaixo para iniciar
        </Typography>
      </Flex>
      <NotFoundSimulationLogo />
      <Button
        text={"Ir para o simulador"}
        onClick={onClickGoToSmartQuotation}
        width={"263px"}
        height={"30px"}
        color={"rgba(89, 0, 204, 1)"}
        backgroundColor={'white'}
        disabled={false}
      />
      <FloatingButton onClick={onClickHelp}/>
    </Flex>
  )
}

export default NotFoundSimulation