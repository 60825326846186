import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { updateHolderUseCase } from "../useCases/mutations/updateHolderMutation";
import { getFromLS } from "../../../../../utils/localStorage";
import { getContractByIdQuery } from "../../../useCases/queries/getContractByIdQuery";
import { validateCPF, validateEmail } from "../../../../../utils/parser";
import { trackPage, trackAction } from "../../../../../utils/segment";

export const holderPageModel = () => {
  const navigate = useNavigate();
  const [holderForm, updateHolderForm] = useState({});
  const [errors, setErrors] = useState({
    name: true,
    cpf: true,
    email: true,
    cellphone: true,
    civilStatus: true,
    occupation: true,
    height: true,
    weight: true,
  });
  trackPage("plg-contract-holder-form");

  const {
    dataContractQuery,
    isLoadingContractQuery,
    isSuccessContractQuery,
  } = getContractByIdQuery()

  const {
    isLoadingUpdateHolderCase,
    isSuccessUpdateHolderCase,
    mutateUpdateHolder,
  } = updateHolderUseCase()
  const onClickConfirm = () => {
    trackAction("plg-contract-holder-form-button");
    const contract = getFromLS("contract")
    mutateUpdateHolder({
      signerType: "holder",
      name: holderForm.name,
      cpf: holderForm.cpf,
      email: holderForm.email,
      cellphone: holderForm.cellphone,
      civilStatus: holderForm.civilStatus,
      occupation: holderForm.occupation,
      height: holderForm.height,
      weight: holderForm.weight,
      contractId: contract.id,
    })
  }

  const onChangeInput = (inputName, value) => {
    validateInput(inputName, value)
    updateHolderForm({
      ...holderForm,
      [inputName]: value,
    })
  }

  const validateInput = (inputName, value) => {
    if (!value.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [inputName]: `${inputName} é obrigatório.`,
      }));
    } else {
      if (inputName === "cpf") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [inputName]: validateCPF(value) ? null : `Digite um CPF válido`,
        }));
      } else if (inputName === "email"){
        setErrors((prevErrors) => ({
          ...prevErrors,
          [inputName]: validateEmail(value) ? null : `Digite um email válido`,
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [inputName]: null,
        }));
      }
    }
  };

  const hasErrors = Object.values(errors).some((error) => error);

  useEffect(() => {
    if(!isLoadingUpdateHolderCase && isSuccessUpdateHolderCase) {
      navigate('/contract/holder/legal-responsible');
    }
  }, [isLoadingUpdateHolderCase, isSuccessUpdateHolderCase])

  useEffect(() => {
    if (!isLoadingContractQuery && isSuccessContractQuery) {
      const holder = dataContractQuery.signers?.find(signer => signer.type === "holder");
      if (holder) {
        setErrors({
          name: !holder.name,
          cpf: !holder.cpf,
          email: !holder.email,
          cellphone: !holder.cellphone,
          civilStatus: !holder.civilStatus,
          occupation: !holder.occupation,
          height: !holder.height,
          weight: !holder.weight,
        })
        updateHolderForm({
          name: holder.name,
          cpf: holder.cpf,
          email: holder.email,
          cellphone: holder.cellphone,
          civilStatus: holder.civilStatus,
          occupation: holder.occupation,
          height: holder.height,
          weight: holder.weight,
        })
      }
    }
  }, [isLoadingContractQuery, isSuccessContractQuery])

  return {
    onClickConfirm,
    onChangeInput,
    holderForm,
    isLoading: isLoadingContractQuery || isLoadingUpdateHolderCase,
    hasErrors,
    errors,
  }
}
