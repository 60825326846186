import Flex from "../../../components/atoms/Flex/Flex";
import Typography from "../../../components/atoms/Typography/Typography";
import Button from "../../../components/molecules/Button"
import Input from "../../../components/molecules/Input"
import { emailPageModel } from "./viewModel/emailPageModel";
import Loading from "../../../components/molecules/Loading/Loading"
import PasswordErrorMessages from "./components/PasswordErrorMessages";

const EmailPageRoute = () => {
  const {
    onClickConfirm,
    emailInput,
    pswdInput,
    onChangePswrdInput,
    onChangeEmailInput,
    isLoading,
    errorMessage,
    setIsValidPassword,
    isValidPassword,
  } = emailPageModel()
  return (
    <Flex
      flexDirection={"column"}
      justifyContent={"space-around"}
      alignItems={"center"}
      style={{
        height: "100%",
        gap: "12px",
        marginTop: 90,
      }}
    >
      {isLoading && (<Loading/>)}
      <Flex
        flexDirection={"column"}
        alignItems={"center"}
        gapSize
      >
        <Flex
          flexDirection={"column"}
          style={{
            marginLeft: 20,
            marginRight: 20,
            marginBottom: 10,
          }}
        >
          <Typography
            variant="h5"
            fontWeight={400}
            color={"terceary"}
            style={{ textAlign: "center" }}
          >
            Estamos muito felizes em te conhecer
          </Typography>
          <Typography
            variant={"subheading2"}
            fontWeight={400}
            color={"terceary"}
            style={{
              marginTop: 12,
              textAlign: "center",
            }}
          >
            Para darmos sequência à contratação do plano de saúde escolhido, por favor informe o seu e-mail:
          </Typography>
        </Flex>
        {errorMessage && (
          <Typography
            variant="subheading2"
            fontWeight={400}
            color={"error"}
            style={{
              marginLeft: 50,
              marginRight: 50,
            }}
          >
            {errorMessage}
          </Typography>
        )}
        <Input
          placeholder={"E-mail"}
          type={"text"}
          defaultValue={emailInput}
          onChange={(e) => onChangeEmailInput(e.target.value)}
          autoComplete={false}
        />
        <Input
          placeholder={"Senha"}
          type={"password"}
          defaultValue={pswdInput}
          onChange={(e) => onChangePswrdInput(e.target.value)}
          autoComplete={false}
        />
        <PasswordErrorMessages
          password={pswdInput}
          setIsValidPassword={setIsValidPassword}
        />
      </Flex>
      <Button
        text={"Continuar"}
        onClick={onClickConfirm}
        width={"250px"}
        height={"20px"}
        backgroundColor={'white'}
        disabled={isLoading || !isValidPassword}
        style={{
          borderWidth: "1.5px 1.5px",
        }}
      />
    </Flex>
  )
}

export default EmailPageRoute;