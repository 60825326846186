import { useNavigate } from "react-router-dom";
import { getContractByIdQuery } from "../../useCases/queries/getContractByIdQuery";
import { useEffect } from "react";
import { setFromLS } from "../../../../utils/localStorage";
import { trackPage, trackAction } from "../../../../utils/segment";

export const homePageModel = () => {
  const {
    dataContractQuery,
    isLoadingContractQuery,
    isSuccessContractQuery,
  } = getContractByIdQuery()
  trackPage("plg-contract-home");

  const navigate = useNavigate();

  const onClickHolderCard = () => {
    navigate('/contract/holder');
  }

  const onClickCompanyCard = () => {
    navigate('/contract/company');
  }

  const onClickDependentsCard = () => {
    navigate('/contract/dependents/home');
  }

  const onClickFinishButton = () => {
    trackAction("plg-contract-home-finish-button");
    navigate('/contract/success');
  }

  const calculateCompanyProgress = () => {
    const completedCompany = dataContractQuery?.company ? 1 : 0

    return completedCompany
  }

  const calculateHolderProgress = () => {
    const holderSigner = dataContractQuery?.signers?.find(signer => signer.type === "holder");

    if (!holderSigner) return 0
    
    const holderSum = Object.entries(holderSigner).reduce((acc, [key, value]) => {
      if (
        key === "name" ||
        key === "cpf" ||
        key === "email" || 
        key === "cellphone" || 
        key === "civilStatus" || 
        key === "occupation" ||
        key === "weight" ||
        key === "height"
      ) return acc + 1;

      if (key === "documents") {
        return acc + (value?.length || 0);
      }
      return acc
    }, 0)

    const legalSum = dataContractQuery?.legalResponsible ? 
      Object
        .entries(dataContractQuery?.legalResponsible)
        .reduce((acc, [key]) => {
          if (
            key === "email" || 
            key === "cellphone"
          ) return acc + 1;

          return acc
        }, 0)
      : 2

    const TOTAL_REQUIRED_FIELDS = 12
    const completedSum = (holderSum + legalSum) > TOTAL_REQUIRED_FIELDS ?
      TOTAL_REQUIRED_FIELDS :
      (holderSum + legalSum)
    return Math.floor((completedSum / TOTAL_REQUIRED_FIELDS) * 100) / 100
  }

  const calculateDependentsProgress = () => {
    const dependents = dataContractQuery?.dependents?.length
    if (!dependents) {
      return 1;
    }
    const dependentsSum = dataContractQuery?.signers?.reduce((acc, signer) => {
      if (signer.type !== "dependent") return acc;

      const sum = calculateDependentProgress(signer.simulationDependentId);
      if (!sum) return acc
      return acc + sum
    }, 0);

    if (!dependentsSum) return 0;

    const TOTAL_REQUIRED_FIELDS = 8 * dependents
    return Math.floor((dependentsSum / TOTAL_REQUIRED_FIELDS) * 100) / 100;
  }

  const calculateDependentProgress = (dependentId) => {
    const dependentSigner = dataContractQuery?.signers?.find(
      signer => signer.simulationDependentId === dependentId,
    );

    if (!dependentSigner) return 0
    
    const dependentSum = Object.entries(dependentSigner).reduce((acc, [key, value]) => {
      if (
        key === "name" ||
        key === "cpf" ||
        key === "email" || 
        key === "cellphone" || 
        key === "civilStatus" || 
        key === "occupation" ||
        key === "weight" ||
        key === "height"
      ) return acc + 1;

      if (key === "documents") {
        return acc + (value?.length || 0);
      }
      return acc
    }, 0)

    const completedSum = dependentSum > 8 ? 8 : dependentSum
    
    return completedSum
  }

  const allCompleted = () => {
    const holder = calculateHolderProgress()
    const company = calculateCompanyProgress()
    const dependents = calculateDependentsProgress()

    const sum = holder + company + dependents
    return sum === 3
  }

  const onClickHelp = () => {
    trackAction("plg-contract-home-help-button")
    const message = `Olá, preciso de ajuda`
    const url = `https://api.whatsapp.com/send?phone=5521971779781&text=${encodeURI(message)}&app_absent=0`;
    window.open(url);
  }

  useEffect(() => {
    if(!isLoadingContractQuery && isSuccessContractQuery) {
      setFromLS("contract", dataContractQuery);
    }
  }, [isLoadingContractQuery, isSuccessContractQuery])

  return {
    onClickHolderCard,
    onClickCompanyCard,
    onClickDependentsCard,
    onClickFinishButton,
    calculateCompanyProgress,
    calculateHolderProgress,
    isLoading: isLoadingContractQuery,
    hasDependents: dataContractQuery?.dependents?.length || false,
    simulation: dataContractQuery?.simulation || {},
    calculateDependentsProgress,
    isCompleted: allCompleted(),
    onClickHelp,
  }
}
