import Flex from "../../../components/atoms/Flex/Flex";
import Typography from "../../../components/atoms/Typography/Typography";
import Button from "../../../components/molecules/Button"
import Input from "../../../components/molecules/Input"
import { signInPageModel } from "./viewModel/signInPageModel";
import Loading from "../../../components/molecules/Loading/Loading"

const SignInPageRoute = () => {
  const {
    onClickConfirm,
    emailInput,
    pswdInput,
    onChangePswrdInput,
    onChangeEmailInput,
    isLoading,
    errorMessage,
  } = signInPageModel()

  return (
    <Flex
      flexDirection={"column"}
      justifyContent={"space-around"}
      alignItems={"center"}
      style={{
        height: "100%",
        gap: "12px",
      }}
    >
      {isLoading && (<Loading/>)}
      <Flex
        flexDirection={"column"}
        alignItems={"center"}
        gapSize
      >
        <Typography
          variant="h4"
          fontWeight={200}
          color={"terceary"}
          style={{
            marginLeft: 50,
            marginRight: 50,
          }}
        >
          Bem vindo novamente!
        </Typography>
        <Input
          placeholder={"E-mail"}
          type={"text"}
          defaultValue={emailInput}
          onChange={(e) => onChangeEmailInput(e.target.value)}
        />
        <Input
          placeholder={"Senha"}
          type={"password"}
          defaultValue={pswdInput}
          onChange={(e) => onChangePswrdInput(e.target.value)}
        />
        {errorMessage && (
          <Typography
            variant="subheading2"
            fontWeight={400}
            color={"error"}
            style={{
              marginLeft: 50,
              marginRight: 50,
            }}
          >
            {errorMessage}
          </Typography>
        )}
      </Flex>

      <Button
        text={"Continuar"}
        onClick={onClickConfirm}
        width={"250px"}
        height={"20px"}
        color={"rgba(89, 0, 204, 1)"}
        backgroundColor={'white'}
        disabled={isLoading}
        style={{
          borderColor: "rgba(89, 0, 204, 1)",
          border: "solid rgba(89, 0, 204, 1)",
          borderWidth: "1.5px 1.5px",
        }}
      />
    </Flex>
  )
}

export default SignInPageRoute;