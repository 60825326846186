import { FaChevronRight } from "react-icons/fa";
import Flex from "../../../../components/atoms/Flex/Flex";
import Typography from "../../../../components/atoms/Typography/Typography";
import { isMobile } from "react-device-detect";

const ContractCard = ({
  title,
  onClick,
  progress,
  icon,
}) => {
  return (
    <Flex
      onClick={onClick}
      flexDirection={"column"}
      justifyContent={"space-around"}
      alignItems={"center"}
      style={{
        width: isMobile ? "85%" : 327,
        border: "1px solid #D5D9F2",
        borderRadius: "8px",    
        padding: "16px",
        cursor: "pointer",
        alignSelf: "center",
        gap: 10,
      }}
    >
      <Flex
        justifyContent={"space-between"}
        gapSize
        style={{
          width: "100%",
        }}
      >
        <Flex flexDirection={"row"} alignItems={"center"} gapSize>
          {icon}
          <Typography
            variant="subheading2"
            fontWeight={600}
            style={{ color: "#181059" }}
          >
            {title}
          </Typography>
        </Flex>
        <FaChevronRight
          size={16}
          onClick={() => {}}
          style={{
            cursor: "pointer",
            alignSelf: "center",
          }}
          color={"#5900CC"}
        /> 
      </Flex>
      <Flex
        justifyContent={"space-between"}
        style={{
          width: "100%",
        }}
      >
        <progress
          value={progress}
          style={{
            accentColor: progress === 1 ? "#00BF8F" : "#5900CC",
            width: "85%",
          }}
        />
        <Typography
          variant="subheading2"
          fontWeight={400}
          color={"terceary"}
        >
          {progress * 100}%
        </Typography>
      </Flex>
    </Flex>
  )
}

export default ContractCard;