import React, { useState } from 'react';
import Select from 'react-select';
import Typography from '../atoms/Typography/Typography';

function SearchableSelect({
  data,
  onSelect,
  onClear,
  isLoading,
  placeholder,
  multi,
  currentValue,
}) {
  const [inputValue, setInputValue] = useState('');
  const filterOptions = (inputValue) => {
    return data
      .filter(item => item.toLowerCase().includes(inputValue.toLowerCase()))
      .slice(0, 5)
      .map(item => ({ value: item, label: item }));
  };

  const options = filterOptions(inputValue);

  const handleInputChange = (newValue, actionMeta) => {
    if (actionMeta.action === 'input-change') {
      setInputValue(newValue);
      return newValue;
    }
  };

  const handleChange = (selected, actionMeta) => {
    if (actionMeta.action === 'clear') {
      onSelect([]);
      onClear();
    } else if (actionMeta.action === 'remove-value') {
      if (!selected.length) {
        onClear();
      }
    }
    onSelect(selected.value);
  };

  return (
    <div style={{ marginTop: -22 }}>
      <label
        className={'filled'}
        style={{
          position: "relative",
          top: "22px",
          left: "23px",
          zIndex: "1",
          color: '#7D7D7D',
          fontSize: '12px',
          fontWeight: 400,
          lineHeight: '1',
        }}
      >
        Estado civil
      </label>
      <Select
        options={options}
        onInputChange={handleInputChange}
        onChange={handleChange}
        placeholder={placeholder}
        noOptionsMessage={() => 'Nenhuma opção encontrada'}
        isMulti={multi}
        isSearchable={false}
        isClearable={false}
        value={{ value: currentValue, label: currentValue }}
        isDisabled={isLoading}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            backgroundColor: "#F4F1FC",
            height: 55,
            paddingLeft: 12,
          }),
          singleValue: (baseStyles) => ({
            ...baseStyles,
            paddingTop: 12,
          }),
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: '#5900CC',
          },
        })}
      />
    </div>
  );
}

export default SearchableSelect;
