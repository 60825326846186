import Flex from "../../../../../components/atoms/Flex/Flex";
import Typography from "../../../../../components/atoms/Typography/Typography";
import Button from "../../../../../components/molecules/Button";
import Input from "../../../../../components/molecules/Input";
import UploadFile from "../../../../../components/molecules/UploadFile";
import { phoneMask } from "../../../../../utils/masks";
import { legalResponsibleDetailedPageRouteModel } from "./viewModel/legalResponsibleDetailedModel";
import Loading from "../../../../../components/molecules/Loading/Loading"
import Stepper from "../../../../../components/molecules/Stepper";
const LegalResponsibleDetailedPageRoute = () => {
  const {
    onClickConfirm,
    onChangeInput,
    legalResponsibleForm,
    onUploadFile,
    presignedUrl,
    isLoading,
    isUploaded,
    hasErrors,
    errors,
  } = legalResponsibleDetailedPageRouteModel();
  return (
    <Flex flexDirection={"column"} style={{ marginTop: 90 }}>
      <Stepper
        size={4}
        activeStep={3}
      />
      <Flex
        flexDirection={"column"}
        justifyContent={"space-between"}
        alignItems={"center"}
        style={{
          height: "100%",
          gap: "12px",
          marginBottom: "40px",
        }}
      >
        {isLoading && (<Loading/>)}
        <Flex
          flexDirection={"column"}
          style={{
            marginTop: "20px",
            gap: 30,
          }}
        >
          <Typography
            variant="h4"
            fontWeight={200}
            color={"terceary"}
            style={{
              marginLeft: 50,
              marginRight: 50,
              marginBottom: 10,
            }}
          >
            Dados do responsável legal
          </Typography>
          <Flex
            flexDirection={"column"}
            alignItems={"center"}
            style={{
              gap: 40,
              margin: 20,
            }}
          >
            <Input
              placeholder={"E-mail"}
              type={"text"}
              defaultValue={legalResponsibleForm.email}
              onChange={(e) => onChangeInput('email', e.target.value)}
              hasError={!!errors.email}
            />
            <Input
              placeholder={"Telefone"}
              type={"text"}
              defaultValue={phoneMask(legalResponsibleForm.cellphone)}
              onChange={(e) => onChangeInput('cellphone', e.target.value)}
              hasError={!!errors.cellphone}
            />
            <Flex flexDirection={"column"} gapSize>
              <Typography
                variant="subheading1"
                fontWeight={700}
                color={"terceary"}
              >
                Foto do RG ou CNH do Responsável legal
              </Typography>
              <UploadFile
                archiveName={"document"}
                title={"Selecione o arquivo ou arraste e solte aqui"}
                subtitle={"JPG, PNG ou PDF, arquivos de até 5MB."}
                onUploadFile={onUploadFile}
                presignedUrl={presignedUrl}
                isUploaded={isUploaded(legalResponsibleForm.documents, "cpf")}
              />
            </Flex>
          </Flex>
        </Flex>
        <Button
          text={"Continuar"}
          onClick={onClickConfirm}
          width={"163px"}
          height={"30px"}
          color={"rgba(89, 0, 204, 1)"}
          backgroundColor={'white'}
          disabled={isLoading || hasErrors}
        />
      </Flex>
    </Flex>
  )
}

export default LegalResponsibleDetailedPageRoute;