import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getContractByIdQuery } from "../../../../useCases/queries/getContractByIdQuery";
import { trackPage } from "../../../../../../utils/segment";
export const legalResponsiblePageModel = () => {
  const navigate = useNavigate();
  const [isLegalResponsible, setIsLegalResponsible] = useState();
  trackPage("plg-contract-holder-legal-responsible-form");

  const {
    dataContractQuery,
    isLoadingContractQuery,
    isSuccessContractQuery,
  } = getContractByIdQuery()

  const onClickConfirm = () => {
    if (isLegalResponsible) {
      navigate('/contract/holder/documents-upload')
    } else {
      navigate('/contract/holder/legal-responsible/detailed')
    }
  }

  const onChangeInput = (value) => {
    setIsLegalResponsible(value)
  }

  useEffect(() => {
    if (!isLoadingContractQuery && isSuccessContractQuery) {
      if (dataContractQuery.legalResponsible) {
        setIsLegalResponsible(false)
      }
    }
  }, [isLoadingContractQuery, isSuccessContractQuery])

  return {
    onClickConfirm,
    onChangeInput,
    isLegalResponsible,
    isLoading: isLoadingContractQuery,
  }
}
