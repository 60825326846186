import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { signInUseCase } from "../useCases/mutations/signInMutation";
import { getErrorMessage } from "../../../../utils/parser";
import { setAccessToken } from "../../../../utils/localStorage";
import { trackPage, trackAction } from "../../../../utils/segment";

export const signInPageModel = () => {
  const navigate = useNavigate();
  const [emailInput, setEmailIinput] = useState()
  const [pswdInput, setPswdInput] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  trackPage("plg-signin")

  const {
    dataSignInUseCase,
    isSuccessSignInUseCase,
    isLoadingSignInUseCase,
    errorSignInUseCase,
    mutateSignIn,
  } = signInUseCase();

  const onChangePswrdInput = (value) => {
    setPswdInput(value);
  }

  const onChangeEmailInput = (value) => {
    setEmailIinput(value);
  }
  const onClickConfirm = () => {
    trackAction("plg-signin-login-button");
    mutateSignIn({
      email: emailInput,
      password: pswdInput,
    });
  }

  useEffect(() => {
    if (!isLoadingSignInUseCase && isSuccessSignInUseCase) {
      setErrorMessage('')
      setAccessToken(dataSignInUseCase);
      navigate('/contract/home');
    }

    if(errorSignInUseCase) {
      const error = getErrorMessage(errorSignInUseCase)
      if(error === "Email não confirmado") {
        navigate('/signup/confirmation', {
          state: {
            email: emailInput,
            password: pswdInput,
          },
        })
      }
      if(error === 'Email ou senha incorretos') {
        const error = getErrorMessage(errorSignInUseCase)
        setErrorMessage(error)
      }
    }
  }, [
    isLoadingSignInUseCase,
    isSuccessSignInUseCase,
    errorSignInUseCase,
  ])

  return {
    onClickConfirm,
    emailInput,
    pswdInput,
    onChangePswrdInput,
    onChangeEmailInput,
    isLoading: isLoadingSignInUseCase,
    errorMessage,
  }
}
