import axios from "axios";
import { useMutation } from 'react-query';
import { API } from "../../../../../../utils/http";
import { getAccessToken } from "../../../../../../utils/localStorage";

async function updateHolderMutation({
  name,
  cpf,
  email,
  cellphone,
  civilStatus,
  occupation,
  contractId,
  signerType,
  weight,
  height,
}) {
  const response = await axios.post(
    `${API}/contracts/${contractId}/signer`,
    {
      name,
      cpf,
      email,
      cellphone,
      civilStatus,
      occupation,
      signerType,
      weight,
      height,
    },
    {
      headers: {
        Authorization: getAccessToken(),
      },
    },
  );
  return response.data;
}

export const updateHolderUseCase = () => {
  const {
    isSuccess,
    data,
    isLoading,
    error,
    mutate,
  } = useMutation(
    (e) => updateHolderMutation(e),
  );

  return {
    isSuccessUpdateHolderCase: isSuccess,
    dataUpdateHolderCase: data,
    isLoadingUpdateHolderCase: isLoading,
    errorUpdateHolderCase: error,
    mutateUpdateHolder: mutate,
  }
}
