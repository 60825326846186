import React from 'react';
import { FaWhatsapp } from "react-icons/fa";

const FloatingButton = ({
  onClick,
}) => {
  return (
    <div
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
      }}
      onClick={onClick}
    >
      <button
        style={{
          backgroundColor: '#00BF8F',
          color: '#fff',
          width: '50px',
          height: '55px',
          border: 'none',
          cursor: 'pointer',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        title="Tire suas dúvidas"
      >
        <FaWhatsapp size={34} />
      </button>
    </div>
  );
};

export default FloatingButton;
