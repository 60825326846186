import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
  useRouteError,
} from 'react-router-dom';
import LandingPageRoute from './pages/LandingPage/LandingPageRoute';
import Menu from './components/molecules/Menu';
import EmailPageRoute from './pages/SignUp/Email/EmailPageRoute';
import ConfirmationPageRoute from './pages/SignUp/Confirmation/ConfirmationPageRoute';
import SuccessPageRoute from './pages/SignUp/Success/SuccessPageRoute';
import HomePageRoute from './pages/Contract/Home/HomePageRoute';
import HolderPageRoute from './pages/Contract/Holder/Home/HolderPageRoute';
import LegalResponsiblePageRoute
  from './pages/Contract/Holder/LegalResponsible/LegalResponsibleForm/LegalResponsiblePageRoute';
import LegalResponsibleDetailedPageRoute
  from './pages/Contract/Holder/LegalResponsible/LegalResponsibleDetailed/LegalResponsibleDetailedPageRoute';
import HealthDeclarationPageRoute
  from './pages/Contract/Holder/HealthDeclaration/HealthDeclarationPageRoute';
import DocumentsUploadPageRoute
  from './pages/Contract/Holder/DocumentsUpload/DocumentsUploadPageRoute';
import CompanyPageRoute
  from './pages/Contract/Company/CompanyPageRoute';
import DependentsHomePageRoute
  from './pages/Contract/Dependents/DependentsHome/DependentsHomePageRoute';
import DependentPersonalInfoPageRoute
  from './pages/Contract/Dependents/DependentPersonalInfo/DependentPersonalInfoPageRoute';
import DependentHealthDeclarationPageRoute
  from './pages/Contract/Dependents/DependentHealthDeclaration/DependentHealthDeclarationPageRoute'
import DependentDocumentsUploadPageRoute
  from './pages/Contract/Dependents/DependentDocumentsUpload/DependentDocumentsUploadPageRoute'
import ContractSuccessPageRoute
  from './pages/Contract/ContractSuccess/ContractSuccessPageRoute';
import { useAuth } from './utils/authService';
import SignInPageRoute from './pages/SignIn/Email/SignInPageRoute';
import NotFoundSimulation from './pages/Contract/NotFoundSimulation/NotFoundSimulation';

const PrivateRoute = ({ element }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated() ? element : <Navigate to="/" />;
};

function ErrorBoundary() {
  return <div style={{marginTop: "150px"}}>Ops, algo deu errado</div>;
}

const router = createBrowserRouter(
  [
    {
      element: <Menu/>,
      children: [
        {
          path: '',
          element: <LandingPageRoute/>,
          errorElement: <ErrorBoundary />,
        },
        {
          path: '/simulation/404',
          element: <NotFoundSimulation/>,
          errorElement: <ErrorBoundary />,
        },
        {
          path: '/:simulation',
          element: <LandingPageRoute/>,
          errorElement: <ErrorBoundary />,
        },
        {
          path: '/signup/email',
          element: <EmailPageRoute/>,
          errorElement: <ErrorBoundary />,
        },
        {
          path: '/signup/confirmation',
          element: <ConfirmationPageRoute/>,
          errorElement: <ErrorBoundary />,
        },
        {
          path: '/signup/success',
          element: <SuccessPageRoute/>,
          errorElement: <ErrorBoundary />,
        },
        {
          path: '/signin/email',
          element: <SignInPageRoute/>,
          errorElement: <ErrorBoundary />,
        },
        {
          path: '/contract/home',
          element: <PrivateRoute element={<HomePageRoute/>}/>,
          errorElement: <ErrorBoundary />,
        },
        {
          path: '/contract/holder',
          element: <PrivateRoute element={<HolderPageRoute/>}/>,
          errorElement: <ErrorBoundary />,
        },
        {
          path: '/contract/holder/legal-responsible',
          element: <PrivateRoute element={<LegalResponsiblePageRoute/>}/>,
          errorElement: <ErrorBoundary />,
        },
        {
          path: '/contract/holder/legal-responsible/detailed',
          element: <PrivateRoute element={<LegalResponsibleDetailedPageRoute/>}/>,
          errorElement: <ErrorBoundary />,
        },
        {
          path: '/contract/holder/health-declaration',
          element: <PrivateRoute element={<HealthDeclarationPageRoute/>}/>,
          errorElement: <ErrorBoundary />,
        },
        {
          path: '/contract/holder/documents-upload',
          element: <PrivateRoute element={<DocumentsUploadPageRoute/>}/>,
          errorElement: <ErrorBoundary />,
        },
        {
          path: '/contract/company',
          element: <PrivateRoute element={<CompanyPageRoute/>}/>,
          errorElement: <ErrorBoundary />,
        },
        {
          path: '/contract/dependents/home',
          element: <PrivateRoute element={<DependentsHomePageRoute/>}/>,
          errorElement: <ErrorBoundary />,
        },
        {
          path: '/contract/dependents/:dependentId/personal-info',
          element: <PrivateRoute element={<DependentPersonalInfoPageRoute/>}/>,
          errorElement: <ErrorBoundary />,
        },
        {
          path: '/contract/dependents/:dependentId/health-declaration',
          element: <PrivateRoute element={<DependentHealthDeclarationPageRoute/>}/>,
          errorElement: <ErrorBoundary />,
        },
        {
          path: '/contract/dependents/:dependentId/documents-upload',
          element: <PrivateRoute element={<DependentDocumentsUploadPageRoute/>}/>,
          errorElement: <ErrorBoundary />,
        },
        {
          path: '/contract/success',
          element: <PrivateRoute element={<ContractSuccessPageRoute/>}/>,
          errorElement: <ErrorBoundary />,
        },
        {
          path: '*',
          element: <Navigate to="/" replace/>,
          errorElement: <ErrorBoundary />,
        },
      ],
    },
  ],
);

const AppRoutes = () => {
  return (
    <RouterProvider router={router} />
  )
}

export default AppRoutes;
