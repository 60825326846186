import React from 'react';
import Flex from '../atoms/Flex/Flex';

const Stepper = ({
  size,
  activeStep,
}) => {
  const numberToArray = (num) => {
    return Array.from({ length: num }, (_, index) => index + 1);
  };

  const stepperSize = numberToArray(size)
  const stepStyle = {
    width: '8px',
    height: '8px',
    backgroundColor: "#8B91B3",
    borderRadius: '50%',
  };

  const activeStepStyle = {
    ...stepStyle,
    backgroundColor: "#5900CC",
  };

  return (
    <Flex
      justifyContent={"space-between"}
      gapSize
      style={{
        alignSelf: "center",
      }}
    >
      {stepperSize.map((step) => (
        <div key={step} style={step === activeStep ? activeStepStyle : stepStyle}></div>
      ))}
    </Flex>
  );
};

export default Stepper;
