import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { createContractUseCase } from "../useCases/mutations/createContractMutation";
import { getFromLS, setFromLS } from "../../../../utils/localStorage";
import { trackPage, trackAction } from "../../../../utils/segment";

export const successPageModel = () => {
  const navigate = useNavigate();
  trackPage("plg-signup-success");
  const {
    mutateCreateContract,
    isLoadingCreateContractUseCase,
    isSuccessCreateContractUseCase,
    dataCreateContractUseCase,
  } = createContractUseCase();

  const onClickConfirm = () => {
    trackAction("plg-signup-success-button");
    const user = getFromLS('user');
    const simulationId = getFromLS("simulationId");
    mutateCreateContract({
      simulationId,
      userId: user.id,
    })
  }

  useEffect(() => {
    if(!isLoadingCreateContractUseCase && isSuccessCreateContractUseCase) {
      setFromLS("contract", dataCreateContractUseCase);
      navigate('/contract/home');
    }
  }, [isLoadingCreateContractUseCase, isSuccessCreateContractUseCase])
  return {
    onClickConfirm,
    isLoading: isLoadingCreateContractUseCase,
  }
}
