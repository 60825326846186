import Flex from "../../../components/atoms/Flex/Flex";
import Typography from "../../../components/atoms/Typography/Typography";
import Button from "../../../components/molecules/Button";
import ContractCard from "./components/ContractCard";
import { homePageModel } from "./viewModel/homePageModel";
import Loading from "../../../components/molecules/Loading/Loading"
import FloatingButton from "../../../components/molecules/FloatingButton";
import { isMobile } from "react-device-detect";
import { getProviderName } from "../../../utils/i18n-product";
import { convertPriceToMoney } from "../../../utils/masks";
import { FaChevronRight } from "react-icons/fa";
import { ReactComponent as PersonIcon } from "../../../assets/svg/Person.svg"
import { ReactComponent as PersonDependentIcon } from "../../../assets/svg/PersonDependent.svg"
import { ReactComponent as CompanyIcon } from "../../../assets/svg/Company.svg"


const HomePageRoute = () => {
  const {
    onClickCompanyCard,
    onClickDependentsCard,
    onClickHolderCard,
    onClickFinishButton,
    calculateCompanyProgress,
    calculateHolderProgress,
    calculateDependentsProgress,
    hasDependents,
    isLoading,
    isCompleted,
    onClickHelp,
    simulation,
  } = homePageModel();
  return (
    <Flex
      flexDirection={"column"}
      justifyContent={"space-around"}
      alignItems={"center"}
      style={{
        height: "100%",
        width: "100%",
        gap: "12px",
        marginTop: 90,
        paddingBottom: 90,
      }}
    >
      {isLoading && (<Loading/>)}
      <Flex
        flexDirection={"column"}
        alignItems={isMobile ? "": "center"}
        style={{
          width: "85%",
          marginBottom: 10,
        }}
      >
        <Typography
          variant="h4"
          fontWeight={400}
          color={"terceary"}
        >
          Muito bem-vindo!
        </Typography>
        <Typography
          variant={"subheading2"}
          fontWeight={400}
          color={"secondary"}
          style={{
            marginTop: 12,
            alignText: "justify",
          }}
        >
          Agora falta só um pouco para contratar o plano de saúde escolhido.
        </Typography>
      </Flex>
      <Flex
        flexDirection={"column"}
        justifyContent={"center"}
        style={{
          width: isMobile ? "87%" : 360,
          paddingTop: 20,
          paddingBottom: 20,
          backgroundColor: "#F4F1FC",
          borderRadius: 8,
          gap: 14,
        }}
      >
        <Flex
          flexDirection={"column"}
          style={{ gap: 4, paddingLeft: 20 }}
        >
          <Typography
            variant="subheading1"
            fontWeight={500}
            color="terceary"
          >
            {getProviderName(simulation?.selectedProduct?.provider?.name)}
          </Typography>
          <Typography
            variant="h6"
            fontWeight={600}
            color="terceary"
          >
            {simulation?.selectedProduct?.name}
          </Typography>
        </Flex>
        <Flex
          flexDirection={"column"}
          style={{ gap: 4, paddingLeft: 20 }}
        >
          <Typography
            variant="subheading2"
            fontWeight={500}
            color="terceary"
          >
            Valor total
          </Typography>
          <Typography
            variant="h6"
            fontWeight={700}
            color="terceary"
          >
            {convertPriceToMoney(simulation?.selectedProduct?.price)} /mês
          </Typography>
        </Flex>
        {/* <Flex
          gapSize
          style={{ alignItems: "center", paddingLeft: 20, paddingBottom: 20 }}
        >
          <Typography
            variant="subheading2"
            fontWeight={700}
            color="primary"
          >
            Mais detalhes
          </Typography>
          <FaChevronRight
            size={12}
            onClick={() => {}}
            style={{
              cursor: "pointer",
            }}
            color={"#5900CC"}
          /> 
        </Flex> */}
      </Flex>
      <Flex
        flexDirection={"column"}
        style={{
          gap: 24,
          marginTop: 24,
          marginBottom: 36,
          width: "90%",
        }}
      >
        <ContractCard
          title={"Dados do titular"}
          onClick={onClickHolderCard}
          progress={calculateHolderProgress()}
          icon={<PersonIcon />}
        />
        {hasDependents && (
          <ContractCard
            onClick={onClickDependentsCard}
            title={"Dados dos dependentes"}
            progress={calculateDependentsProgress()}
            icon={<PersonDependentIcon />}
          />
        )}
        <ContractCard
          onClick={onClickCompanyCard}
          title={"Dados da empresa"}
          progress={calculateCompanyProgress()}
          icon={<CompanyIcon />}
        />
      </Flex>
      <FloatingButton onClick={onClickHelp}/>
      {isCompleted && (
        <Flex
          flexDirection={"column"}
          style={{
            gap: 40,
          }}
        >
          <Typography
            variant="subheading1"
            fontWeight={400}
            color={"terceary"}
            style={{
              marginLeft: 30,
              marginRight: 30,
              textAlign: "justify",
            }}
          >
            Confirmo que as informações acima são verdadeiras e estou de acordo com os termos de aceite
          </Typography>
          <Button
            text={"Concluir"}
            onClick={onClickFinishButton}
            width={"163px"}
            height={"30px"}
            color={"rgba(89, 0, 204, 1)"}
            backgroundColor={'white'}
            disabled={false}
          />
        </Flex>
      )}
    </Flex>
  )
}

export default HomePageRoute;