import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { updateLegalResponsibleUseCase } from "../useCases/mutations/updateLegalResponsibleMutation";
import { getFromLS } from "../../../../../../utils/localStorage";
import { putObjectToBucket } from "../../../../../../utils/aws/put-object-to-bucket";
import { getPresignedUrl } from "../../../../../../utils/aws/get-presigned-url-mutation";
import { getContractByIdQuery } from "../../../../useCases/queries/getContractByIdQuery";
import { trackPage } from "../../../../../../utils/segment";

export const legalResponsibleDetailedPageRouteModel = () => {
  const navigate = useNavigate();
  const [fileSelected, setFileSelected] = useState();
  const [holderForm, updateHolderForm] = useState({});
  trackPage("plg-contract-holder-legal-responsible-detailed");

  const [errors, setErrors] = useState({
    email: true,
    cellphone: true,
    documentUploaded: true,
  });
  const contract = getFromLS("contract")

  const {
    dataContractQuery,
    isLoadingContractQuery,
    isSuccessContractQuery,
  } = getContractByIdQuery()

  const {
    dataPresignedUrlUseCase,
    isLoadingPresignedUrlUseCase,
    isSuccessPresignedUrlUseCase,
    fetchGetPresignedUrl,
  } = getPresignedUrl();

  const {
    isLoadingUpdateLegalResponsibleCase,
    isSuccessUpdateLegalResponsibleCase,
    mutateUpdateLegalResponsible,
  } = updateLegalResponsibleUseCase();


  const {
    fetchPutObjectToBucket,
    isLoadingPutObjectToBucketUseCase,
    isSuccessPutObjectToBucketUseCase,
  } = putObjectToBucket();


  const onClickConfirm = () => {
    mutateUpdateLegalResponsible({
      rg: holderForm.rg,
      email: holderForm.email,
      cellphone: holderForm.cellphone,
      documents: holderForm.documents,
      contractId: contract.id,
    })
  }

  const handleUploadDocument = (archiveName, file) => {
    const splitName = file.path.split('.') || []
    const type = splitName[splitName.length - 1]
    setFileSelected(file);
    fetchGetPresignedUrl({
      path: `contracts/${contract.id}/legal-responsible/${archiveName}.${type}`,
      contentType: file.type,
    })
  }
  
  const onChangeInput = (inputName, value) => {
    validateInput(inputName, value)
    updateHolderForm({
      ...holderForm,
      [inputName]: value,
    })
  }

  const isUploaded = (documents, documentType) => {
    if (!documents || !documents?.length) return false
    return documents?.some(document => document.type === documentType)
  }

  const validateInput = (inputName, value) => {
    if (!value.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [inputName]: `${inputName} is required.`,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [inputName]: null,
      }));
    }
  };

  const hasErrors = Object.values(errors).some((error) => error);

  useEffect(() => {
    if(!isLoadingUpdateLegalResponsibleCase && isSuccessUpdateLegalResponsibleCase) {
      navigate('/contract/holder/documents-upload');
    }
  }, [isLoadingUpdateLegalResponsibleCase, isSuccessUpdateLegalResponsibleCase])

  useEffect(() => {
    if (!isLoadingPresignedUrlUseCase && isSuccessPresignedUrlUseCase) {
      fetchPutObjectToBucket({
        url: dataPresignedUrlUseCase,
        file: fileSelected,
      })
    }
  }, [isLoadingPresignedUrlUseCase, isSuccessPresignedUrlUseCase])

  useEffect(() => {
    if(!isLoadingPutObjectToBucketUseCase && isSuccessPutObjectToBucketUseCase) {
      const splitName = fileSelected.path.split('.') || []
      const type = splitName[splitName.length - 1]
      const bucketLink = `contracts/${contract.id}/legal-responsible/document.${type}`
      updateHolderForm({
        ...holderForm,
        documents: [{
          type: "cpf",
          bucketLink,
        }],
      })
      validateInput('documentUploaded', bucketLink);
    }
  }, [isLoadingPutObjectToBucketUseCase, isSuccessPutObjectToBucketUseCase])

  useEffect(() => {
    if (!isLoadingContractQuery && isSuccessContractQuery) {
      if (dataContractQuery.legalResponsible) {
        setErrors({
          email: !(dataContractQuery.legalResponsible?.email),
          cellphone: !(dataContractQuery.legalResponsible?.cellphone),
          documentUploaded: !(dataContractQuery.legalResponsible?.documents),
        })
        updateHolderForm({
          rg: dataContractQuery.legalResponsible?.rg ?? "",
          email: dataContractQuery.legalResponsible?.email ?? "",
          cellphone: dataContractQuery.legalResponsible?.cellphone ?? "",
          documents: dataContractQuery.legalResponsible?.documents ?? "",
        })
      }
    }
  }, [isLoadingContractQuery, isSuccessContractQuery])

  return {
    onClickConfirm,
    onChangeInput,
    legalResponsibleForm: holderForm,
    onUploadFile: handleUploadDocument,
    presignedUrl: dataPresignedUrlUseCase,
    isLoadingUpload: isLoadingPresignedUrlUseCase || isLoadingPutObjectToBucketUseCase,
    isLoading: isLoadingPutObjectToBucketUseCase || isLoadingContractQuery || isLoadingUpdateLegalResponsibleCase,
    isUploaded,
    hasErrors,
    errors,
  }
}
