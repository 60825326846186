import { trackAction } from "../../../../utils/segment";

export const notFoundSimulationModel = () => {
  const onClickGoToSmartQuotation = (e) => {
    e.preventDefault();
    trackAction("plg-go-to-simulation")
    const url = `https://simular.ducasaude.com.br/without-simulation`;
    window.open(url, "_self");
  }

  const onClickHelp = () => {
    trackAction("plg-contract-home-help-without-simulation-button")
    const message = `Olá, Criei uma conta e estou sem simulação, preciso de ajuda.`
    const url = `https://api.whatsapp.com/send?phone=5521971779781&text=${encodeURI(message)}&app_absent=0`;
    window.open(url);
  }

  return {
    onClickGoToSmartQuotation,
    onClickHelp,
  }
}