import Flex from "../../../components/atoms/Flex/Flex";
import Typography from "../../../components/atoms/Typography/Typography";
import Button from "../../../components/molecules/Button";
import { contractSuccessPageModel } from "./viewModel/contractSuccessPageModel";
import { ReactComponent as HandsSuccess } from '../../../assets/svg/HandsSuccess.svg';
const ContractSuccessPageRoute = () => {
  const {
    onClickSuccessButton,
  } = contractSuccessPageModel();
  return (
    <Flex
      flexDirection={"column"}
      justifyContent={"space-evenly"}
      alignItems={"center"}
      style={{
        height: "100%",
        gap: "12px",
        marginTop: 90,
      }}
    >
      <Flex
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        gapSize
      >
        <HandsSuccess />
        <Typography
          variant="h5"
          fontWeight={400}
          color={"terceary"}
          style={{
            marginLeft: 50,
            marginRight: 50,
          }}
        >
          O que acontece agora?
        </Typography>
        <Typography
          variant="subheading1"
          fontWeight={400}
          color={"terceary"}
          style={{
            marginLeft: 50,
            marginRight: 50,
            marginBottom: 50,
          }}
        >
          Nosso time entrará em contato via whatsapp para verificação das informações em até 24 horas. 
        </Typography>
      </Flex>
      <Button
        text={"Indique um amigo"}
        onClick={onClickSuccessButton}
        width={"283px"}
        height={"30px"}
        color={"rgba(89, 0, 204, 1)"}
        backgroundColor={'white'}
        disable={false}
      />
    </Flex>
  )
}

export default ContractSuccessPageRoute;