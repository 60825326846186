import axios from "axios";
import { useMutation } from 'react-query';
import { API } from "../../../../../utils/http";
import { removeAccessToken } from "../../../../../utils/localStorage";

async function signInMutation({ email, password }) {
  const response = await axios.post(
    `${API}/users/signin`,
    {
      email,
      password,
    },
  );
  return response.data;
}

export const signInUseCase = () => {
  const {
    isSuccess,
    data,
    isLoading,
    error,
    mutate,
  } = useMutation(
    (e) => signInMutation(e),
    {
      onSuccess: (data) => {
        removeAccessToken();
      },
    },
  );

  return {
    isSuccessSignInUseCase: isSuccess,
    dataSignInUseCase: data,
    isLoadingSignInUseCase: isLoading,
    errorSignInUseCase: error,
    mutateSignIn: mutate,
  }
}
